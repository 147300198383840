import Configs from '../constants/configs';

export class PageEvent {
  page: number;
  limit: number;
  sort: any;

  // query: any;

  constructor(page: number = Configs.pagination.startPage,
              limit: number = Configs.pagination.defaultSize) {
    this.page = page;
    this.limit = limit;
  }

  query() {
    const data: any = {};
    if (this.page != null) {
      data.page = this.page;
    }

    if (this.limit != null) {
      data.limit = this.limit;
    }

    if (this.sort != null) {
      data.sort = this.sort;
    }

    return data;
  }
}
