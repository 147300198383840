import { Injectable } from '@angular/core';
import { ApiCommon } from '@shared/apis/common';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ParamsBuilder } from '@shared/models/params-builder';
import Configs from '@shared/constants/configs';
import Roles from '@shared/constants/roles';

@Injectable({
  providedIn: 'root'
})
export class TicketApi extends ApiCommon {

  ticket$ = new BehaviorSubject(null);

  getTicket(ticketNo, company): Observable<any> {
    return this.apiServer.ticket.getTicket(ticketNo, company);
  }

  resendMessage(params): Observable<any> {
    return this.apiServer.ticket.resendMessage(params);
  }

  offerReissue(ticketNo): Observable<any> {
    return this.apiServer.ticket.offerReissue(ticketNo);
  }
}
