import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuardService } from '@core/auth/auth-guard.service';
import { AuthService } from '@core/auth/auth.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthRequestInterceptor } from '@core/interceptors/auth-request.interceptor';
import { AuthResponseInterceptor } from '@core/interceptors/auth-response.interceptor';
import { ScriptLoaderService } from '@core/script-loader/script-loader.service';
import { MobileGuardService } from '@core/auth/mobile-guard.service';

@NgModule({
  declarations: [],
  providers: [
    AuthService,
    AuthGuardService,
    MobileGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthRequestInterceptor,
      multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthResponseInterceptor,
      multi: true
    }
  ],
  imports: [
    CommonModule
  ]
})
export class CoreModule { }
