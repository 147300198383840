<div class="dp-list">
  <div class="d-flex list-top">
    <div class="col-sm-12 col-md-6 header-left">
      <span class="pr-18px" *ngIf="showFilter">
        <font-awesome [icon]="'fa-light fa-filter btn-cursor'" (click)="onPopFilterClick($event)"></font-awesome>
      </span>
      <span class="d-inline-flex total-items">
        Showing {{totalItems || 0 | number}} Rows
      </span>
      <span class="pr-18px" *ngIf="showQ">
        <input type="search"
               (change)="onSearch($event)"
               class="form-control border-light rounded ml"
               [placeholder]="placeHolderForQ">
      </span>
    </div>
    <div class="col-sm-12 col-md-6">
      <div class="pager-container">
        <ng-template #popTemplate>
          <form [formGroup]="pageForm">
            <div class="form-group">
              <label for="page">Page</label>
              <input
                class="form-control form-control-sm"
                type="number"
                id="page"
                formControlName="page"
                min="0"
                max="{{ totalPages }}"
              />
            </div>
            <div class="form-group">
              <label for="pageSize">Page Size</label>
              <select class="form-control form-control-sm w-100"
                      id="pageSize" formControlName="pageSize">
                <option
                  value="{{ option }}"
                  *ngFor="let option of pageSizeOptions">
                  {{ option }} items
                </option>
              </select>
            </div>
            <div class="mt-3">
              <button type="submit"
                      class="btn btn-primary btn-sm"
                      (click)="onPopApplyClick($event)">Apply</button>
              <button type="submit"
                      class="btn btn-default btn-sm"
                      (click)="onPopCancelClick($event)">Cancel</button>
            </div>
          </form>
        </ng-template>

        <div class="btn btn-default"
             [popover]="popTemplate"
             placement="bottom"
             #pop="bs-popover">
          {{ currentPage | number }} of {{ totalPages | number }}
        </div>

        <pager
          [totalItems]="totalItems"
          [align]="true"
          [(ngModel)]="currentPage"
          (pageChanged)="onPageChanged($event)"
          [itemsPerPage]="itemsPerPage"
          previousText="<"
          nextText=">">
        </pager>
        <button
          class="btn btn-default"
          type="button"
          *ngIf="selectedItem"
          (click)="toggle()">
          <i class="fas" [ngClass]="{'fa-outdent': showDetail && selectedItem, 'fa-indent': !showDetail}"></i>
        </button>

      </div>

    </div>
  </div>

  <div class="table-responsive" #listContainer>

    <no-result-table [type]="'no-result-found'" [totalItems]="totalItems"></no-result-table>
    <ngx-simplebar id="ngxSimplebar" class="table-simple-bar">
      <ng-content></ng-content>
    </ngx-simplebar>

    <!-- spinner in table-->
    <ngx-spinner name="table" size="default" bdColor="rgba(255,255,255,0.8)" color="#000000" [fullScreen]="false" type="line-spin-clockwise-fade"></ngx-spinner>
  </div>
</div>
