import { Injectable } from '@angular/core';
import { ApiCommon } from '@shared/apis/common';
import { Observable } from 'rxjs';
import { ParamsBuilder } from '@shared/models/params-builder';
import Configs from '@shared/constants/configs';
import Roles from '@shared/constants/roles';

@Injectable({
  providedIn: 'root'
})
export class UserApi extends ApiCommon {

  getUsers(pageEvent, q?: any, query?: any): Observable<any> {
    const builder = new ParamsBuilder();
    builder.pageEvent = pageEvent;
    builder.selectFields = ['username', 'lastName', 'firstName', 'twoFactorAuth', 'roles', 'dealers', 'status', 'auth',
                            'organization', 'organizationName', 'tel', 'userType', 'lastLogin'];

    if (q) {
      builder.q = q;
    }

    if (query) {
      builder.query = query;
    }

    return this.apiServer.user.getUsers(builder.build());
  }

  getUser(username): Observable<any> {
    return this.apiServer.user.getUser(username);
  }

  saveUser(user): Observable<any> {
    return this.apiServer.user.saveUser(user);
  }

  getActiveECouponOwner(company: string): Observable<any> {
    const fields = ['firstName', 'lastName', 'username', 'status'];

    const query = {
      status: 'ACTIVE',
    };

    const builder = new ParamsBuilder();
    builder.selectFields = fields;
    builder.query = query;
    builder.limit = Configs.pagination.listAll;
    builder.sort = 'lastName firstName';

    return this.apiServer.user.getUsers(builder.build());
  }
}
