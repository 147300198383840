import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import Utils from '../utils/utils';

@Pipe({ name: 'ticketNo' })
export class TicketNoPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(ticketNo: string): any {
    return this.sanitizer.bypassSecurityTrustHtml(Utils.ticketNo(ticketNo));
  }

}
