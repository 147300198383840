import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimplebarAngularModule } from 'simplebar-angular';
import { ClickOutsideModule } from 'ng-click-outside';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgOtpInputModule } from 'ng-otp-input';
import { DisplayNamePipe } from '@shared/pipes/display-name.pipe';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TruncatePipe } from '@shared/pipes/truncate.pipe';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { HqDatePipe } from '@shared/pipes/hq-date.pipe';
import { HqDateTimePipe } from '@shared/pipes/hq-date-time.pipe';
import { HqDateOptionalTimePipe } from '@shared/pipes/hq-date-optional-time.pipe';
import { LocalDatePipe } from '@shared/pipes/local-date.pipe';
import { BooleanToStringPipe } from '@shared/pipes/boolean-to-string.pipe';
import { TicketNoPipe } from '@shared/pipes/ticket-no.pipe';
import { CapacityPipe } from '@shared/pipes/capacity.pipe';
import { KeysPipe } from '@shared/pipes/keys.pipe';
import { TelPipe } from './pipes/tel.pipe';
import { DkTimeAgoPipe } from '@shared/pipes/dk-time-ago.pipe';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TatPipe } from '@shared/pipes/tat.pipe';
import { FilterPipe } from '@shared/pipes/filter.pipe';
import { HqDateTimeFormatPipe } from '@shared/pipes/hq-date-time-format.pipe';
import { NgxMaskModule } from 'ngx-mask';
import { SelectDefaultPipe } from '@shared/pipes/select-default.pipe';
import { SecurePipe } from './pipes/secure.pipe';
import { FileUploadModule } from 'ng2-file-upload';
import { DkDatepickerModule } from '@shared/libs/date-picker';
import { PopupModule } from '@shared/services/popup-service/popup.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SimplebarAngularModule,
    ClickOutsideModule,
    TranslateModule,
    NgxSpinnerModule,
    NgSelectModule,
    NgOtpInputModule,
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    BsDropdownModule.forRoot(),
    ButtonsModule.forRoot(),
    TimepickerModule.forRoot(),
    NgxMaskModule.forRoot(),
    PerfectScrollbarModule,
    FileUploadModule,
    PopupModule,
  ],
  declarations: [
    DisplayNamePipe,
    TruncatePipe,
    HqDatePipe,
    HqDateTimePipe,
    HqDateOptionalTimePipe,
    LocalDatePipe,
    BooleanToStringPipe,
    TicketNoPipe,
    CapacityPipe,
    KeysPipe,
    TelPipe,
    DkTimeAgoPipe,
    FilterPipe,
    TatPipe,
    HqDateTimeFormatPipe,
    SelectDefaultPipe,
    SecurePipe,
  ],
  providers: [],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    // FeatherModule,
    SimplebarAngularModule,
    ClickOutsideModule,
    TranslateModule,
    NgxSpinnerModule,
    NgSelectModule,
    NgOtpInputModule,
    ModalModule,
    PaginationModule,
    BsDatepickerModule,
    TabsModule,
    TooltipModule,
    PopoverModule,
    BsDropdownModule,
    ButtonsModule,
    TimepickerModule,
    PerfectScrollbarModule,
    NgxMaskModule,
    FileUploadModule,

    DisplayNamePipe,
    TruncatePipe,
    HqDatePipe,
    HqDateTimePipe,
    HqDateOptionalTimePipe,
    LocalDatePipe,
    BooleanToStringPipe,
    TicketNoPipe,
    CapacityPipe,
    KeysPipe,
    TelPipe,
    DkTimeAgoPipe,
    TatPipe,
    FilterPipe,
    HqDateTimeFormatPipe,
    SelectDefaultPipe,
    SecurePipe,
    DkDatepickerModule,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule
    };
  }
}
