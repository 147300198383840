import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-moblie-layout',
  templateUrl: './moblie-layout.component.html',
  styleUrls: ['./moblie-layout.component.scss']
})
export class MoblieLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
