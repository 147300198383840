<div class="detail-container" [ngClass]="{'in-tabs': isTabs, 'min-width-detail': !isTabs}">
  <div class="row">
    <div class="col-2 detail-label">Offer ID</div>
    <div class="col-4 detail-value">{{_offerDetail?.offerId}}</div>
    <div class="col-2 detail-label">Status</div>
    <div class="col-4 detail-value">
      <badge [text]="_offerDetail?.status"
             [color]="color[_offerDetail?.status?.toLowerCase()]"
             [size]="''">
      </badge>
    </div>
  </div>

  <div class="row">
    <div class="col-2 detail-label">Offer Group</div>
    <div class="col-4 detail-value">
      <badge [text]="_offerDetail?.offerGroup"
             [color]="color['offerGroup']"
             [size]="'wd-auto'"></badge>
    </div>
    <div class="col-2 detail-label">{{_offerDetail?.amount ? 'Amount' : 'Discount'}}</div>
    <div class="col-4 detail-value">
      <ng-container *ngIf="_offerDetail?.amount; else discountTemp;">
        {{ _offerDetail?.amount | currency }}
      </ng-container>
      <ng-template #discountTemp>
        {{ _offerDetail?.discount?.toString().concat('%') }}
      </ng-template>
    </div>

  </div>

  <div class="row">
    <div class="col-2 detail-label">Expires</div>
    <div class="col-4 detail-value">{{_offerDetail?.expiresInDay}}</div>
    <div class="col-2 detail-label">Start / End Date</div>
    <div class="col-4 detail-value">
      {{_offerDetail?.startDate| date: 'short'}} - {{_offerDetail?.endDate| date: 'short'}}
    </div>
  </div>

  <div class="row">
    <div class="col-2 detail-label">Name</div>
    <div class="col-4 detail-value">{{_offerDetail?.name}}</div>
    <div class="col-2 detail-label">Description</div>
    <div class="col-4 detail-value">{{_offerDetail?.description}}</div>
  </div>

  <div class="row">
    <div class="col-2 detail-label">Channel</div>
    <div class="col-4 detail-value">{{_offerDetail?.channel}}</div>
    <div class="col-2 detail-label">Offer Type</div>
    <div class="col-4 detail-value">{{_offerDetail?.offerType}}</div>
  </div>

  <div class="row">
    <div class="col-2 detail-label">Concurrent</div>
    <div class="col-4 detail-value">{{_offerDetail?.concurrent}}</div>
    <div class="col-2 detail-label">Coupon Type</div>
    <div class="col-4 detail-value">{{_offerDetail?.couponType}}</div>
  </div>

  <div class="row">
    <div class="col-2 detail-label">Condition</div>
    <div class="col-4 detail-value">{{_offerDetail?.condition}}</div>
    <div class="col-2 detail-label">Benefit</div>
    <div class="col-4 detail-value">{{_offerDetail?.benefit}}</div>
  </div>
  <div class="row">
    <div class="col-2 detail-label">Person in Change</div>

<!-- <div class="col-4 detail-value">{{ _offerDetail?.owner?.lastName }}{{_offerDetail?.owner?.lastName ? ',' : ''}} {{ _offerDetail?.owner?.firstName }}</div>-->
    <div class="col-4 detail-value">{{ _offerDetail?.owner | displayName }}</div>
  </div>
  <ng-container *ngIf="!isTabs">
    <div class="row">
      <div class="col-2 detail-label">Inventory</div>
      <div class="col-10 detail-value-table">
        <table class="table table-bordered">
          <thead class="table-light">
          <tr>
            <th>Stock</th>
            <th>Daily Usage</th>
            <th>Lead Time</th>
            <th>Safety Stock</th>
            <th>Reorder Point</th>
            <th>Days</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="text-right">{{ _offerDetail?.couponCount }}</td>
            <td class="text-right">{{ _offerDetail?.dailyUsage | number: '1.1' }}</td>
            <td class="text-right">{{ _offerDetail?.leadTime }}<span *ngIf="_offerDetail?.leadTime !== null">{{ _offerDetail?.leadTime > 1 ? ' Days' : 'day'}}</span></td>
            <td class="text-right">{{ _offerDetail?.safetyStock }}</td>
            <td class="text-right">{{ _offerDetail?.reorderPoint }}</td>
            <td class="text-right">{{ _offerDetail?.days |number: '1.0'  }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col-2 detail-label">Status Count</div>
      <div class="col-10 detail-value-table">
        <table class="table table-bordered">
          <thead class="table-light">
          <tr>
            <th *ngFor="let key of keys(_offerDetail?.coupons)">
              {{key | titlecase}}
            </th>
            <th>Total</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="text-right" *ngFor="let value of values(_offerDetail?.coupons)">
              {{ value }}
            </td>
            <td class="text-right">
              {{couponTotalCount(_offerDetail?.coupons)}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>
</div>
