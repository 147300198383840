import { Injectable } from '@angular/core';
import { ApiServer } from '@shared/apis/servers';

@Injectable({
  providedIn: 'root'
})
export class ApiCommon {
  constructor(protected apiServer: ApiServer) {
  }
}
