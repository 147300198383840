import { Injectable } from '@angular/core';
import { BsDatepickerState, initialDatepickerState } from './bs-datepicker.state';
import { BehaviorSubject } from 'rxjs';
import { bsDatepickerReducer } from './bs-datepicker.reducer';
import { Action, MiniState, MiniStore } from '@shared/libs/mini-ngrx';

@Injectable({ providedIn: 'platform' })
export class BsDatepickerStore extends MiniStore<BsDatepickerState> {
  constructor() {
    const _dispatcher = new BehaviorSubject<Action>({
      type: '[datepicker] dispatcher init'
    });
    const state = new MiniState<BsDatepickerState>(
      initialDatepickerState,
      _dispatcher,
      bsDatepickerReducer
    );
    super(_dispatcher, bsDatepickerReducer, state);
  }
}
