import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'booleanToString' })
export class BooleanToStringPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    return value ? 'Yes' : 'No';
  }

}
