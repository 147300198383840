import { Pipe, PipeTransform } from '@angular/core';
import DateUtils from '@shared/utils/date-utils';

@Pipe({ name: 'hqDateTime' })
export class HqDateTimePipe implements PipeTransform {

  transform(val: any): any {
    return DateUtils.utc2HQDateTime(val);
  }

}
