import { Pipe, PipeTransform } from '@angular/core';
import Utils from '@shared/utils/utils';

@Pipe({ name: 'tel' })
export class TelPipe implements PipeTransform {

  transform(tel: any): any {
    return Utils.tel(tel);
  }

}
