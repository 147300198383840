export default class Configs {
  static readonly package = require('../../../../package.json');

  static readonly pagination = {
    startPage: 1,
    defaultSize: 25,
    sizeOptions: [10, 25, 50, 100],
    listAll: -1
  };

  static readonly hq = {
    timezone: 'America/New_York',
    countryCode: 'US',
  };

  static readonly defaultDetailType = 'DET001';

  static readonly fileLimit = {
    limit: 20 * 1024 * 1024 // 20MB
  };

  static readonly format = {
    dateStr: 'YYYYMMDD',
    date: 'MM/DD/YYYY',
    dateMMDDYYYY: 'MM/DD/YYYY',
    dateDDMMYYYY: 'DD/MM/YYYY',
    dateYYYYMMDD: 'YYYY/MM/DD',
    dateMMDDYY: 'MM/DD/YY',
    dateMMYYYY: 'MM/YYYY',
    dateDay: 'DD',
    time: 'h:mm a',
    hour: 'hh',
    minute: 'mm',
    a: 'a',
    dateTime: 'MM/DD/YYYY h:mm a',
    dateTime2: 'MM-DD-YYYY h:mm a',
    zonedDate: 'YYYY-MM-DD',
    zonedDateTime: 'YYYY-MM-DD HH:mm:ss',
    serverLocalDate: 'YYYY-MM-DD',
    serverLocalTime: 'hh:mm:ss',
    serverLocalDateTime: 'YYYY-MM-DDTHH:mm:ssz',
    serverLocalDateTime2: 'YYYY-MM-DDTHH:mm:ss',
    serverISODateTime: 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]',
    pickerTime: 'h:mm a',
    initTime: '00:00:00'
  };

  static readonly timeSlot = {
    AM: {
      start: '08:00',
    },
    PM: {
      start: '12:00',
    },
    EV: {
      start: '16:00',
    },
    DY: {
      start: '08:00',
    },
  }

  static readonly badgeColor = {
    service: '#B96518',
    detail: '#48634B',
    reason: '#C4C4C4',
    status: '#1289A4',
    product: '#1289A4',
    warranty: '#1289A4',
    cardStatus: '#FF0000',

    // User Customer Type
    agent: '#D67216',
    admin: '#7D3A95',

    // User Status
    active: '#2C76CA',
    inactive: '#C7271F',
    default: '#676767',
    null: '#ffffff',

    offerGroup: '#9d9d9d',
    couponStatus: {
      issued: '#9747ff',
      delivered: '#818181',
      open: '#2189ff',
      verified: '#034ea2',
      used: '#08a6bc',
      sent: '#000000',
      expired: '#A53838',
      void: '#A53838',
      cancelled: '#A53838',
    },
    /*gcicStatus: {
      ST005: '#FF0000',
      ST030: '#FF0000',
      ST033: '#FF0000',
      ST010: '#2C76CA',
      ST015: '#2C76CA',
      ST020: '#2C76CA',
      ST025: '#2C76CA',
      ST035: '#818181',
      ST040: '#818181',
      ST050: '#818181',
      ST051: '#818181',
      ST052: '#818181',
      ST055: '#818181',
      ST060: '#818181',
    },*/
    gcicStatus: '#514EE1',
    gcicStatusDefault: '#818181',
    grayDefault: '#C4C4C4'
  }
}
