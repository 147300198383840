import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthService } from '@core/auth/auth.service';
import { MenuItem } from '@layouts/menu/menu.model';
import { MenuService } from '@layouts/menu/menu.service';
import { environment } from '@env/environment';
import { finalize } from 'rxjs/operators';
import Utils from '@shared/utils/utils';
import { MfaRegisterComponent } from '@routes/auth/mfa/mfa-register/mfa-register.component';
import { SpinnerService } from '@shared/services/spinner.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

/**
 * Topbar Component
 */
export class TopbarComponent implements OnInit, OnDestroy {

  readonly environment = environment;
  profileName = 'prod';

  mode: string | undefined;
  myInfo: any;
  isDropDownMenu = false;
  bsModalRef: BsModalRef;

  menuItems: MenuItem[] = [];
  pusherClient: any;

  @Output() mobileMenuButtonClicked = new EventEmitter();

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isDropDownMenu = false;
  }

  constructor(private authService: AuthService,
              protected spinnerService: SpinnerService,
              private modalService: BsModalService,
              private menuService: MenuService) {
  }

  @Output() settingsButtonClicked = new EventEmitter();

  ngOnInit(): void {
    this.menuItems = this.menuService.getMenu();
    this.myInfo = this.authService.getUser();
    this.profileCheck();
  }

  profileCheck() {
    switch (this.environment.environment) {
      case 'dev':
        this.profileName = 'dev';
        break;
      case 'local':
        this.profileName = 'local';
        break;
      default:
        this.profileName = '';
        break;
    }
  }

  ngOnDestroy() {
    if (this.pusherClient) {
      this.pusherClient.unbind();
      this.pusherClient.unsubscribe();
    }
  }

  /***
   * Language Value Set
   */
  setLanguage(text: string, lang: string, flag: string) {
    /*this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);*/
  }

  /**
   * Logout the user
   */
  logout(ev) {
    ev.stopPropagation();
    ev.preventDefault();

    this.authService.logout();
  }

  topMenuOutSideClick() {
    if (!this.isDropDownMenu){
      return
    }
    this.isDropDownMenu = false;
  }

  dropDownToggle() {
    this.isDropDownMenu = !this.isDropDownMenu;
  }

  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }

  registerMFA() {
    this.spinnerService.start('other-layout-spinner');
    this.authService.getQRCode()
      .pipe(finalize(() => this.spinnerService.stop('other-layout-spinner')))
      .subscribe(result => {
        const { qrCode } = result;
        const initialState = {
          qrCode,
        };
        this.bsModalRef = this.modalService.show(MfaRegisterComponent, { initialState, class: 'modal-lg' });
        this.bsModalRef.content.onClose.subscribe(() => {
          Utils.showMessageDialog(this.modalService, 'MFA device registered successfully.');
        }, err => {
          Utils.showError(err, 'MFA Register');
        });

      });
  }
}
