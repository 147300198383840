<div class="modal-header">
  <h4 class="modal-title">How to Setup MFA Device</h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <img src="assets/img/ico-xmark.svg" class="btn-icon-32px" alt=""/>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-1">
      <i class="fa fa-circle-1 fa-2x"></i>
    </div>
    <div class="col-11">
      Install application such as Google Authenticator, Duo Mobile, or Authy app on your mobile device or computer.
    </div>
  </div>
  <div class="row mt-20px">
    <div class="col-1">
      <i class="fa fa-circle-2 fa-2x"></i>
    </div>
    <div class="col-11">
      Open your authenticator app and scan the QR code.<br />
      <img src="{{qrCode}}" alt="code" />
    </div>
  </div>
  <div class="row mt-20px">
    <div class="col-1">
      <i class="fa fa-circle-3 fa-2x"></i>
    </div>
    <div class="col-11">
      Enter the code below.<br />
      <div *ngIf="errorMessage" class="text-danger mb-2">{{ errorMessage }}</div>
      <form [formGroup]="valForm">
        <div class="row">
          <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
        </div>
      </form>
    </div>
  </div>
  <div class="row mt-20px">
    <div class="col-1">
      <i class="fa fa-circle-4 fa-2x"></i>
    </div>
    <div class="col-11">
      <button type="button" class="btn btn-dark wd-184px me-auto" (click)="register()" [disabled]="code?.length<6">Register</button>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="d-sm-flex  justify-content-between mt-4">
    <button type="button" class="btn btn-outline-dark btn-lg wd-184px me-auto" (click)="cancel()">Close</button>
  </div>
</div>
