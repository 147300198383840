import { Pipe, PipeTransform } from '@angular/core';
import DateUtils from '@shared/utils/date-utils';

@Pipe({ name: 'hqDate' })
export class HqDatePipe implements PipeTransform {

  transform(val: any): any {
    return DateUtils.utc2HQDate(val);
  }

}
