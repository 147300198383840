import { Injectable } from '@angular/core';
import { BaseBackend } from '@shared/apis/servers/base-backend';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportBackendServer extends BaseBackend {

  getReports(url, builder): Observable<any> {
    return this.get(`/report/${url}`, { params: builder });
  }


}
