import { BehaviorSubject, EMPTY, Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import Utils from '@shared/utils/utils';
import sharedStorage from '@shared/services/shared-storage';

@Injectable()
export class AuthResponseInterceptor implements HttpInterceptor {
  private inRefreshingToken = false;
  private accessToken$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private injector: Injector,
              private router: Router,
              private authService: AuthService) {
    this.authService.token$.subscribe((res: any) => {
      this.accessToken$.next(res?.toeken?.access_token);
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next
      .handle(request).pipe(
        catchError(response => {
          if (response instanceof HttpErrorResponse) {
            if ([401, 403].includes(response.status)) {
              return this.handleError(request, next, response);
            }
          }

          return observableThrowError(response);
        }));
  }

  private handleError(request: HttpRequest<any>, next: HttpHandler, response: HttpErrorResponse) {
    // mobile error
    if (request.url.includes('/mobile')) {
      Utils.showError(response.error);
      this.router.navigate(['/mobiles']);
      return EMPTY;
    }

    // not permit
    if ([403].includes(response.status)) {
      Utils.showError(response.error);
      this.authService.setToken(null);
      this.router.navigate(['/auth/login']);
      return EMPTY;
    }

    if (this.inRefreshingToken) {
      // in the middle of refreshing
      return this.accessToken$.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(token => {
          return next.handle(this.setAuthHeader(request, token));
        }),
        catchError(error => {
          console.error('Error while processing refresh token', error);
          setTimeout(() => {}, 500);

          return EMPTY;
        }),
      );
    } else {
      // will start refreshing
      this.inRefreshingToken = true;
      this.accessToken$.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((res: any) => {
          if (res) {
            this.authService.setToken(res);
            const accessToken = res.token.access_token;
            this.accessToken$.next(accessToken);

            return next.handle(this.setAuthHeader(request, accessToken));
          }

          return this.logoutUser();
        }),
        catchError(error => {
          console.error('Error while processing refresh token', error);
          const message = 'Your session has expired.';
          return this.logoutUser(message);
        }),
        finalize(() => {
          this.inRefreshingToken = false;
          if (!this.authService.getToken()) {
            this.authService.setToken(null);
          }
        }));
    }
  }

  private setAuthHeader(req: HttpRequest<any>, accessToken: string): HttpRequest<any> {
    return req.clone({ setHeaders: { Authorization: 'Bearer ' + accessToken } });
  }

  private logoutUser(message = null) {
    this.authService.logout();
    return observableThrowError(message);
  }
}
