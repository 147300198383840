import { Pipe, PipeTransform } from '@angular/core';
import Utils from '@shared/utils/utils';

@Pipe({
  name: 'truncate'
})

export class TruncatePipe implements PipeTransform {

  transform(value: string, args: string[]): string {
    if (!value) {
      return '';
    }

    return Utils.truncate(value, args);
  }
}
