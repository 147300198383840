<div class="detail-container position-relative" [ngClass]="{'in-tabs': isTabs}">
  <div *ngIf="ticketErrMsg" class="ticket-err-msg">{{ticketErrMsg}}</div>
  <ngx-spinner name="ticket-info-spinner" size="default" [fullScreen]="false"
               bdColor="rgba(0,0,0,0.2)" type="ball-scale-pulse">
    <p class="m-0" style="color: white" > Loading... </p>
  </ngx-spinner>
  <div class="row">
    <div class="col-2 detail-label">Ticket#</div>
    <div class="col-4 detail-value">
      <span [innerHTML]="ticket?.ticketNo || detailTicketNo | ticketNo"></span>
    </div>
    <div class="col-2 detail-label">S/N<span *ngIf="ticket?.imei"> / IMEI</span></div>
    <div class="col-4 detail-value">{{ticket?.serialNo}}<span *ngIf="ticket?.imei"> / {{ticket?.imei}}</span></div>

    <div class="col-2 detail-label">Model Code</div>
    <div class="col-4 detail-value">{{ticket?.modelCode}}</div>
    <div class="col-2 detail-label">Warranty</div>
    <div class="col-4 detail-value">{{ticket?.warrantyStatus}}
      <span *ngIf="ticket?.warrantyTerm">
          <span *ngIf="ticket?.warrantyStatus">
            /
          </span>
        {{ ticket?.warrantyTermName }}
        </span>
      {{ ticket?.wtyException }}
    </div>

    <div class="col-2 detail-label">Product</div>
    <div class="col-4 detail-value">
      <div class="d-flex mb-8px">
        <badge class="mr-8px" [text]="ticket?.productType"
               [color]="color.grayDefault" [size]="'wd-xxs'"></badge>
        {{ticket?.productTypeName}}</div>
      </div>
    <div class="col-2 detail-label">Complete Date</div>
    <div class="col-4 detail-value">{{ticket?.completeDate | hqDateOptionalTime}}</div>

    <div class="col-2 detail-label">Posting Date</div>
    <div class="col-4 detail-value">{{ticket?.createDate | hqDate}}</div>
    <div class="col-2 detail-label">Symptom</div>
    <div class="col-4 detail-value">
      <ul class="list-unstyled">
        <ng-container *ngFor="let symptom of ticket?.symptoms">
          <li *ngIf="symptom.code" class="d-flex mb-8px">
            <badge class="mr-8px" [text]="symptom.code"
                   [color]="color.grayDefault" [size]="'wd-xxs'"></badge>
            {{symptom.description}}
          </li>
        </ng-container>
      </ul>
    </div>

    <div class="col-2 detail-label h-70px">Status / Reason</div>
    <div class="col-10 detail-value">
      <div class="d-flex mb-8px">
        <badge class="mr-8px" [text]="ticket?.gcicStatus"
               [color]="color.gcicStatus" [size]="'wd-xxs'"></badge>
        <span>{{ ticket?.gcicStatusName }}</span>
        <span *ngIf="ticket?.warrantyErrors?.length">
            <b>(Submitted 'Repair Completed' - Need check error)</b>
        </span>
      </div>
      <div class="d-flex mb-8px" *ngIf="ticket?.delayReason">
        <badge class="mr-8px text-uppercase" [text]="ticket?.delayReason"
               [color]="color.grayDefault" [size]="'wd-xxs'"></badge>
        <div>{{ ticket?.delayReasonName }}</div>
        <div *ngIf="ticket?.delayReason === 'RMZ54'">
          ({{ ticket?.enr.rejectReason }})
        </div>
      </div>
    </div>


    <div class="col-2 detail-label">Customer #</div>
    <div class="col-4 detail-value">{{ticket?.bpNo}}</div>
    <div class="col-2 detail-label">Customer Name</div>
    <div class="col-4 detail-value">{{ticket | displayName}} </div>

    <div class="col-2 detail-label">Email</div>
    <div class="col-4 detail-value">{{ticket?.email}}</div>
    <div class="col-2 detail-label">Phone</div>
    <div class="col-4 detail-value">{{ticket?.cellPhone | tel}}</div>
  </div>
</div>
