import { Subject } from 'rxjs';
import { environment } from '@env/environment';

export default class SharedStorage {
  private static keyPrefix = '__shared_storage_';
  private static postKey = '__shared_post_message_';

  static _ready = false;

  static token$ = new Subject<string>();

  static ready() {
    if (this._ready) {
      return;
    }
    this.log('start event listener');
    window.addEventListener('storage', event => {
      if (event.key === this.postKey) {
        try {
          this.handlePostedMessage(JSON.parse(event.newValue));
        } catch (err) {
          // do nothing
        }
      }
    });
    this._ready = true;
    this.log('joining session');
    this.postMessage('joinSession')
  }

  static setItem(key: string, value: string) {
    this.ready();
    key = this.makeKey(key);
    sessionStorage.setItem(key, value)
    this.postMessage('setItem', JSON.stringify({ key, value }));
  }

  static clear() {
    this.clearSession();
    this.postMessage('clear');
  }

  static getItem(key) {
    this.ready();
    key = this.makeKey(key);
    return sessionStorage.getItem(key)
  }

  static removeItem(key) {
    this.ready();
    key = this.makeKey(key);
    sessionStorage.removeItem(key)
    this.postMessage('removeItem', key);
  }

  private static postMessage(action, data?) {
    if (!environment.useSharedSession) {
      return;
    }
    const message = {
      action,
      data,
    }
    // this.log('postMessage', message);
    window.localStorage.setItem(this.postKey, JSON.stringify(message));
    window.localStorage.removeItem(this.postKey);
  }

  private static makeKey(key) {
    return `${this.keyPrefix}${key}`.toLocaleLowerCase();
  }

  private static handlePostedMessage(message) {
    this.log('receiveMessage', message);
    const { action, data } = message;
    switch (action) {
      case 'joinSession':
        // send welcome packages
        const message = Object.keys(sessionStorage).reduce((sum, x) => {
          if (x.startsWith(this.keyPrefix)) {
            sum[x] = sessionStorage.getItem(x);
          }
          return sum;
        }, {});
        this.postMessage('welcomeSession', JSON.stringify(message));
        break;
      case 'welcomeSession':
        if (data) {
          const json = JSON.parse(data);
          Object.keys(json).forEach(key => {
            this.setSessionStorage(key, json[key]);
          })
        }
        break;
      case 'setItem':
        const json = JSON.parse(data);
        this.setSessionStorage(json.key, json.value);
        break;
      case 'clear':
        this.clearSession();
        break;
      case 'removeItem':
        this.setSessionStorage(data);
        break;
    }
  }

  private static setSessionStorage(key, value?) {
    if (typeof value === 'undefined' || value === null) {
      sessionStorage.removeItem(key);
    }
    switch (key) {
      case this.makeKey('token'):
        this.token$.next(value);
        break;
      default:
        break;
    }
  }

  private static clearSession() {
    Object.keys(sessionStorage).forEach( x => {
      if (x.startsWith(this.keyPrefix)) {
        sessionStorage.removeItem(x);
      }
    });
  }

  private static log(message, ...optionalParams: any[]) {
    console.log(`[SharedSession]`, message, optionalParams);
  }
}
