import { Injectable } from '@angular/core';
import { ApiCommon } from '@shared/apis/common';
import { Observable } from 'rxjs';
import { ParamsBuilder } from '@shared/models/params-builder';

@Injectable({
  providedIn: 'root'
})
export class AbnormalApi extends ApiCommon {

  getAbnormalList(pageEvent, q?: any, query?: any): Observable<any> {
    const builder = new ParamsBuilder();
    builder.pageEvent = pageEvent;
    if (q) {
      builder.q = q;
    }

    if (query) {
      builder.query = query;
    }

    return this.apiServer.abnormal.getAbnormalList(builder.build());
  }

  getAbnormal(ticketNo): Observable<any> {
    return this.apiServer.abnormal.getAbnormal(ticketNo);
  }

  getAbnormalCase(ticket, abnormalCase): Observable<any> {
    const {
      company,
      ticketNo,
      bpNo,
      modelCode,
      serialNo,
    } = ticket;

    const params = {
      company,
      ticketNo,
      bpNo,
      modelCode,
      serialNo,
    };

    return this.apiServer.abnormal.getAbnormalCase(params, abnormalCase);
  }

  saveAbnormalCase(data): Observable<any> {
    return this.apiServer.abnormal.saveAbnormalCase(data);
  }
}
