<div class="authentication-container">
  <div class="left-item" *ngIf="isDesktop">
    <div><img src="assets/img/main-logo.svg" alt="" height="180" class=""></div>
  </div>

  <div class="right-item">
    <router-outlet></router-outlet>
  </div>
  <ngx-spinner name="other-layout-spinner" size="default" [fullScreen]="true"
               type="line-spin-clockwise-fade"></ngx-spinner>
</div>
