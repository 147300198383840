import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash-es';
import Utils from '@shared/utils/utils';
import Configs from '@shared/constants/configs';

@Component({
  selector: 'app-history-info',
  templateUrl: './history-info.component.html',
  styleUrls: ['./history-info.component.scss']
})
export class HistoryInfoComponent implements OnInit {

  @Input() set detailInfo(value) {
    if (!Utils.isEmpty(value)) {
      this.historyInfo = _.orderBy(value.statusHistory, ['date'], ['desc']) || [];
    } else {
      this.historyInfo = [];
    }
  }

  readonly color  = Configs.badgeColor;

  historyInfo: any;

  constructor() { }

  ngOnInit(): void {
    // this.ticketMessages = _.orderBy(this.detailInfo.ticket.messages, ['date'], ['desc']) || [];
  }

}
