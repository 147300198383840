import { Injectable } from '@angular/core';
import { ApiCommon } from '@shared/apis/common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MobileApi extends ApiCommon {

  getCoupon(params): Observable<any> {
    return this.apiServer.mobile.getCoupon(params);
  }


}
