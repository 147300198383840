import { Pipe, PipeTransform } from '@angular/core';
import Utils from '@shared/utils/utils';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(value: any, searchText: string, property: any = {}): any {
    if (!value) {
      return null;
    }

    if (searchText) {
      return Utils.filter(value, searchText, property);
    }

    return value;
  }

}
