import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiCommon } from '@shared/apis/common';
import { ParamsBuilder } from '@shared/models/params-builder';
import Configs from '@shared/constants/configs';
import { saveAs as importedSaveAs } from 'file-saver';
import Utils from '@shared/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class MasterApi extends ApiCommon {

  getConstant(code: any): Observable<any> {
    return this.apiServer.master.getConstant(code);
  }

  fileDownload(url: string, fileType: string, query?: any, q?: any, params?: any): Observable<any> {
    const builder = new ParamsBuilder();
    builder.limit = Configs.pagination.listAll;

    if (query) {
      builder.query = query;
    }

    if (q) {
      builder.q = q;
    }

    if(params) {
      builder.params = params;
    }

    return this.apiServer.master.download(url, fileType, builder.build());
  }

  tsvCellsUpload(data: any, code?, queryString?: any): Observable<any> {
    return this.apiServer.master.tsvCellsUpload(data, code, queryString);
  }
}
