import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { PopupMessage } from '@shared/services/popup-service/models/popupMessage';

@Injectable()
export class PopupApiService {
  private baseUrl = environment.api.backend;

  constructor(protected http: HttpClient) {

  }

  getUserPopup(): Observable<any> {
    return this.http.get(`${this.baseUrl}/popup`);
  }

  agree(popupMessage: PopupMessage, agreed): Observable<any> {
    return this.http.post(`${this.baseUrl}/popup/${popupMessage.code}/agree`, {
      ...popupMessage,
      agreed,
    });
  }

  hide(popupMessage: PopupMessage, hide): Observable<any> {
    return this.http.post(`${this.baseUrl}/popup/${popupMessage.code}/hide`, {
      ...popupMessage,
      hide,
    });
  }
}
