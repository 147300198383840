export const environment = {
  name: 'eCoupon',
  production: false,
  isLocal: false,
  environment: 'dev',
  useSharedSession: true,
  api: {
    baseUrl: 'https://ecoupon.qa.nscsp.com',
    backend: 'https://ecoupon.qa.nscsp.com/api',
  },

  pusher: {
    appKey: '8a21f0fb47d37845216a',
    cluster: 'mt1',
    authEndpoint: '/auth/pusher',
  },

  // mapKey: 'AIzaSyDhYvSH_Fl0rNEXeKSKeesv_ZX03HgH6nk',

  limit: {
    contentImageSize: 2 * 1024 * 1024,
    uploadFileSize: 100 * 1024 * 1024,
  }
};
