import { MenuItem } from './menu.model';
import { Company } from '@shared/enums/company';
import Roles from '@shared/constants/roles';


export const MENU: MenuItem[] = [
  {
    label: 'MENUITEMS.DASHBOARD.TEXT',
    icon: 'fa-table',
    link: '/dashboard',
    componentId: 'dashboard',
    permits: [
      { company: Company.Sea, roles: Roles.admin },
      { company: Company.Sea, roles: Roles.couponManager },
      { company: Company.Sea, roles: Roles.enrManager },
    ],
  }, {
    label: 'MENUITEMS.OFFERS.TEXT',
    icon: 'fa-brands fa-buffer',
    link: '/offers',
    componentId: 'offers',
    permits: [
      { company: Company.Sea, roles: Roles.admin },
      { company: Company.Sea, roles: Roles.couponManager },
      { company: Company.Sea, roles: Roles.enrManager },
    ],
  }, {
    label: 'MENUITEMS.COUPONS.TEXT',
    icon: 'fa-gift-card',
    link: '/coupons',
    componentId: 'coupons',
    permits: [
      { company: Company.Sea, roles: Roles.admin },
      { company: Company.Sea, roles: Roles.couponManager },
      { company: Company.Sea, roles: Roles.enrManager },
      { company: Company.Sea, roles: Roles.special },
      { company: Company.Sea, roles: Roles.agent },
    ]
  }, {
    label: 'MENUITEMS.ABNORMAL.TEXT',
    icon: 'fa-list-check',
    link: '/abnormal',
    componentId: 'abnormal',
    permits: [
      { company: Company.Sea, roles: Roles.admin },
      { company: Company.Sea, roles: Roles.couponManager },
      { company: Company.Sea, roles: Roles.enrManager },
      { company: Company.Sea, roles: Roles.special },
    ]
  }, {
    label: 'MENUITEMS.DAILY-STATUS.TEXT',
    icon: 'fa-calendar',
    link: '/reports/daily-status',
    componentId: 'dailyStatus',
    permits: [
      { company: Company.Sea, roles: Roles.admin },
      { company: Company.Sea, roles: Roles.couponManager },
      { company: Company.Sea, roles: Roles.enrManager },
      { company: Company.Sea, roles: Roles.special },
      { company: Company.Sea, roles: Roles.agent },
    ]
  }, {
    label: 'MENUITEMS.REPAIR-OFFERS.TEXT',
    icon: 'fa-screwdriver-wrench',
    link: '/reports/repair-offers',
    componentId: 'repairOffers',
    permits: [
      { company: Company.Sea, roles: Roles.admin },
      { company: Company.Sea, roles: Roles.couponManager },
      { company: Company.Sea, roles: Roles.enrManager },
      { company: Company.Sea, roles: Roles.special },
      { company: Company.Sea, roles: Roles.agent },
      { company: Company.Sea, roles: Roles.repairOfferManager },
    ]
  }, {
    label: 'MENUITEMS.SURVEY.TEXT',
    icon: 'fa-memo',
    link: '/survey',
    componentId: 'offers',
    permits: [
      { company: Company.Sea, roles: Roles.admin },
    ],
  }
  /*, {
    label: 'MENUITEMS.SA-OFFERS.TEXT',
    icon: 'fa-address-card',
    link: '/reports/sa-offers',
    componentId: 'saOffers',
    permits: [
      { company: Company.Sea, roles: Roles.admin },
      { company: Company.Sea, roles: Roles.couponManager },
      { company: Company.Sea, roles: Roles.enrManager },
      { company: Company.Sea, roles: Roles.special },
    ]
  }*/, {
    label: 'MENUITEMS.USERS.TEXT',
    icon: 'fa-user-check',
    link: '/users',
    componentId: 'users',
    permits: [
      { company: Company.Sea, roles: Roles.admin },
    ]
  }
];
