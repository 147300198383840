import { Injectable } from '@angular/core';
import { BaseBackend } from '@shared/apis/servers/base-backend';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AbnormalBackendServer extends BaseBackend {

  getAbnormalList(builder): Observable<any> {
    return this.get('/abnormal', { params: builder });
  }

  getAbnormal(ticketNo): Observable<any> {
    return this.get(`/abnormal/${ticketNo}`);
  }

  getAbnormalCase(params, abnormalCase): Observable<any> {
    return this.get(`/abnormal/case/${abnormalCase}`, { params });
  }

  saveAbnormalCase(data): Observable<any> {
    const { abnormalId } = data;
    return this.patch(`/abnormal/${abnormalId}`, data);
  }
}
