import { Injectable } from '@angular/core';
import { AuthService } from '@core/auth/auth.service';
import Role from '@shared/constants/role';
import * as _ from 'lodash-es';
import { Subject } from 'rxjs';
import { MENU } from '@layouts/menu/menu';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  selectedMenu$: Subject<any> = new Subject();

  constructor(private authService: AuthService) {
  }

  getMenu() {
    const token = this.authService.getToken();
    const menu = this.setMenu();
    return menu.reduce((prev, item) => {

      if (token.userType === Role.admin) {
        prev.push(item);
        return prev;
      }
      const targetRoles = this.authService.getRoles();

      item.permits.forEach(f => {
        if (f.roles.some(x => targetRoles.includes(x))) {
          if (!prev.some(x => x.id === item.id)) {
            prev.push(item);
          }
        }
      });

      return prev;
    }, []);
  }

  setMenu() {
    const cloneDeep = _.cloneDeep(MENU);
    return cloneDeep.map((m, i) => {
      m.id = i + 1;
      if (m.subItems && m.subItems.constructor === [].constructor) {
        m.subItems = m.subItems.map((sub, j) => {
          sub.id = `${m.id}${j + 1}`;
          sub.parentId = m.id;
          return sub;
        });
      }
      return m;
    });
  }
}
