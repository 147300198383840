import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'font-awesome',
  templateUrl: './font-awesome.component.html',
  styleUrls: ['./font-awesome.component.scss']
})
export class FontAwesomeComponent implements OnInit {

  @Input()
  cssClass: any;

  @Input()
  icon: any;

  @Input()
  color: any;

  constructor() { }

  ngOnInit(): void {
  }

}
