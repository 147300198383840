<div class="modal-header">
  <h4 class="modal-title pull-left">{{popupMessage?.title}}</h4>
  <!-- hide close button if agree is defined and not agreed yet -->
  <button *ngIf="!popupMessage?.agree || !popupMessage?.agree?.required || popupMessage?.agree?.agreed"
          (click)="dismiss()" aria-label="Close" class="btn-close" type="button">
  </button>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-12 content-container">
      <div [innerHTML]="popupMessage?.messageHtml || toHtml(popupMessage?.message)"></div>
    </div>
  </div>
  <div class="row" *ngIf="!popupMessage?.agree?.agreed">
    <div class="col-12 agree-container">
      <div class="agree-box">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" [checked]="agreeCheckBox" (change)="checkAgree()">
        </div>
      </div>
      <div class="agree-content" [innerHTML]="popupMessage?.agree?.messageHtml || toHtml(popupMessage?.agree?.message)">
      </div>
    </div>
  </div>
  <div class="row" *ngIf="popupMessage?.agree?.agreed">
    <div class="col-12 agree-container">
      <div class="agree-box" [innerHTML]="popupMessage?.agree?.messageHtml || toHtml(popupMessage?.agree?.message)"></div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <!-- agree -->
  <ng-container *ngIf="popupMessage?.agree && !popupMessage?.agree?.agreed">
    <button (click)="agree(true)" class="btn btn-dark mr-8px" type="button" [disabled]="!agreeCheckBox">AGREE</button>

    <!-- different button title depending on agree required -->
    <button (click)="agree(false)"
            *ngIf="popupMessage?.agree?.required"
            class="btn btn-light" type="button">QUIT
    </button>
    <button (click)="dismiss()"
            *ngIf="!popupMessage?.agree?.required"
            class="btn btn-light" type="button">CLOSE
    </button>
  </ng-container>
  <!-- general -->
  <ng-container *ngIf="!popupMessage?.agree || popupMessage?.agree?.agreed">
    <button (click)="hide()" *ngIf="popupMessage?.hide"
            class="btn btn-dark mr-8px" type="button">
      {{popupMessage?.hide?.button || 'DO NOT SHOW THIS MESSAGE'}}
    </button>
    <button (click)="dismiss()" class="btn btn-light" type="button">CLOSE</button>
  </ng-container>
</div>
