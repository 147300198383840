import { AuthGuardService } from '@core/auth/auth-guard.service';
import { OtherLayoutComponent } from '@layouts/other-layout/other-layout.component';
import { NotFoundComponent } from '@components/not-found/not-found.component';
import { AuthLayoutComponent } from '@layouts/auth-layout/auth-layout.component';
import { LayoutComponent } from '@layouts/layout/layout.component';
import { MoblieLayoutComponent } from '@layouts/moblie-layout/moblie-layout.component';

const hb = import('./hb/hb.module');
const home = import('./home/home.module');
const auth = import('./auth/auth.module');
const users = import('./users/users.module');
const dashboard = import('./dashboard/dashboard.module');
const offers = import('./offers/offers.module');
const coupons = import('./coupons/coupons.module');
const abnormal = import('./abnormal/abnormal.module');
const mobiles = import('./mobiles/mobiles.module');
const reports = import('./reports/reports.module');
const survey = import('./survey/survey.module');

export const routes = [
  {
    path: '',
    canActivate: [
      AuthGuardService,
    ],
    component: LayoutComponent,
    children: [
      { path: '', loadChildren: () => home.then(m => m.HomeModule) },
      { path: 'dashboard', loadChildren: () => dashboard.then(m => m.DashboardModule) },
      { path: 'offers', loadChildren: () => offers.then(m => m.OffersModule) },
      { path: 'coupons', loadChildren: () => coupons.then(m => m.CouponsModule) },
      { path: 'users', loadChildren: () => users.then(m => m.UsersModule) },
      { path: 'abnormal', loadChildren: () => abnormal.then(m => m.AbnormalModule) },
      { path: 'reports', loadChildren: () => reports.then(m => m.ReportsModule) },
      { path: 'survey', loadChildren: () => survey.then(m => m.SurveyModule) },
    ]
  }, {
    path: '',
    component: AuthLayoutComponent,
    children: [
      { path: 'auth', loadChildren: () => auth.then(m => m.AuthModule) },
    ]
  }, {
    path: '',
    component: OtherLayoutComponent,
    children: [
      { path: 'hb', loadChildren: () => hb.then(m => m.HbModule) },
    ]
  }, {
    path: '',
    component: OtherLayoutComponent,
    children: [
      { path: 'error', component: NotFoundComponent },
    ]
  }, {
    path: '',
    component: MoblieLayoutComponent,
    children: [
      { path: 'mobiles', loadChildren: () => mobiles.then(m => m.MobilesModule) },
    ]
  }, {
    path: '**', pathMatch: 'full', component: NotFoundComponent
  },
];
