import { Injectable } from '@angular/core';
import { MasterApi } from '@shared/apis/master.api';
import { AuthApi } from '@shared/apis/auth.api';
import { UserApi } from '@shared/apis/user.api';
import { CompanyApi } from '@shared/apis/company.api';
import { OfferApi } from '@shared/apis/offer.api';
import { CouponApi } from '@shared/apis/coupon.api';
import { environment } from '@env/environment';
import { AbnormalApi } from '@shared/apis/abnormal.api';
import { TicketApi } from '@shared/apis/ticket.api';
import { MobileApi } from '@shared/apis/mobile.api';
import { ReportApi } from '@shared/apis/report.api';
import { SurveyApi } from '@shared/apis/survey.api';

@Injectable({ providedIn: 'root' })
export class ApiService {

  readonly backend = environment.api.backend;

  constructor(public auth: AuthApi,
              public master: MasterApi,
              public company: CompanyApi,
              public offer: OfferApi,
              public coupon: CouponApi,
              public abnormal: AbnormalApi,
              public mobile: MobileApi,
              public report: ReportApi,
              public user: UserApi,
              public ticket: TicketApi,
              public survey: SurveyApi,
  ) {
  }
}
