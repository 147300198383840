import { Component, Input, OnInit } from '@angular/core';
import Configs from '@shared/constants/configs';
import Utils from '@shared/utils/utils';

@Component({
  selector: 'app-offer-info',
  templateUrl: './offer-info.component.html',
  styleUrls: ['./offer-info.component.scss']
})
export class OfferInfoComponent implements OnInit {

  @Input() set detailInfo(value) {
    if (!Utils.isEmpty(value)) {
      this._offerDetail = value;
    }
  }

  @Input()
  isTabs = false;

  readonly color = Configs.badgeColor;

  _offerDetail: any;

  constructor() { }

  ngOnInit(): void {
  }

  couponTotalCount(coupons) {
    if (!coupons) {
      return null;
    }
    return Object.values(coupons).reduce((sum: number, x: number) => {
      return sum + x;
    }, 0);
  }

  keys(coupons) {
    if (!coupons) {
      return null;
    }
    return Object.keys(coupons);
  }

  values(coupons) {
    if (!coupons) {
      return null;
    }
    return Object.values(coupons);
  }
}
