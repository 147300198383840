<form [formGroup]="dateRangeForm">
  <div class="input-calendar-group">
    <div class="input-group input-calendar">
      <input class="form-control input-search" type="text" formControlName="periodStart"
             (bsValueChange)="openCalendar($event, dateRangeForm.controls['periodStart'])"
             #dps="dkDatepicker" dkDatepicker [readOnly]="true" [bsConfig]="bsConfig"/>
      <div class="input-group-append">
        <span class="input-group-text bg-transparent">
          <img class="duration-icon" src="assets/img/ico-date-border.svg" (click)="dps.toggle()"
               [attr.aria-expanded]="dps.isOpen"/>
        </span>
      </div>
    </div>
    <div class="tilde">~</div>
    <div class="input-group input-calendar">
      <input class="form-control input-search" type="text" formControlName="periodEnd"
             [maxDate]="endBsDate.maxDate" [minDate]="endBsDate.minDate"
             (bsValueChange)="openCalendar($event, dateRangeForm.controls['periodEnd'])"
             #dps2="dkDatepicker" dkDatepicker [readOnly]="true" [bsConfig]="bsConfig"/>
      <div class="input-group-append">
        <span class="input-group-text bg-transparent">
        <img
          class="duration-icon"
          src="assets/img/ico-date-border.svg"
          (click)="dps2.toggle()"
          [attr.aria-expanded]="dps2.isOpen"/>
        </span>
      </div>
    </div>
  </div>
</form>
