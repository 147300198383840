import { Component, Input, OnInit } from '@angular/core';
import Utils from '@shared/utils/utils';
import Configs from '@shared/constants/configs';

@Component({
  selector: 'app-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.scss']
})
export class OrderStatusComponent implements OnInit {

  @Input() set detailInfo(value) {
    if (!Utils.isEmpty(value)) {
      this.orderInfo = value;
    }
  }

  readonly color = Configs.badgeColor;

  orderInfo: any;

  constructor() { }

  ngOnInit(): void {
    /* TODO TEST
    this.orderInfo = {
      orderId: 'TEST01',
      orderStatus: 'OUT_OF_DELIVERY',
      amount: '150',
      product: 'RF26J7510SR',
      productCode: 'REF_REF',
      history: [{
        status: 'PLACE_ORDERED',
        date: new Date(),
      }, {
        status: 'PICK & PACk',
        date: new Date(),
      }, {
        status: 'PICK & PACk',
        date: new Date(),
      }, {
        status: 'PICK & PACk',
        date: new Date(),
      }]
    }*/
  }

}
