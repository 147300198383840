import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import Configs from '@shared/constants/configs';
import { ApiService } from '@shared/apis';
import Utils from '@shared/utils/utils';
import { SpinnerService } from '@shared/services/spinner.service';
import { finalize } from 'rxjs/operators';


@Component({
  selector: 'app-ticket-info',
  templateUrl: './ticket-info.component.html',
  styleUrls: ['./ticket-info.component.scss']
})
export class TicketInfoComponent {
  readonly color = Configs.badgeColor;

  @Input() set detailInfo(value) {
    if (!Utils.isEmpty(value)) {
      this.ticket = value;
    } else {
      this.ticket = {};
    }
  }

  @Input()
  isTabs = false;

  @Input() set ticketErrMsg(value) {
    this._ticketErrMsg = value;
  }

  get ticketErrMsg() {
    return this._ticketErrMsg;
  }

  _ticketErrMsg = null;

  @Input() set detailTicketNo(value) {
    this._detailTicketNo = value;
  }

  get detailTicketNo() {
    return this._detailTicketNo;
  }

  _detailTicketNo = null;

  @Output()
  reTicketInfo = new EventEmitter(null);

  ticket: any;
}
