<!-- ========== Left Sidebar Start ========== -->
<div class="vertical-menu">

  <!-- LOGO -->
  <div class="navbar-brand-box px-18px">
    <a class="logo logo-light">
      <span class="logo-lg">
        <img src="/assets/img/logo.png" height="14" class="logo" alt="">
      </span>
      <span class="logo-sm btn-cursor">
        <img src="/assets/img/logo.png" width="52" class="logo" alt="">
      </span>
    </a>
  </div>

  <!--- Sidemenu -->

  <ngx-simplebar *ngIf="menuItems.length" class="sidebar-menu-scroll" style="max-height: 100vh; z-index: 100;">
    <!--- Sidemenu -->
    <div id="sidebar-menu">
      <!-- Left Menu Start -->
      <ul class="metismenu list-unstyled" id="side-menu">

        <ng-container *ngFor="let item of menuItems">
          <li class="menu-title" *ngIf="item.isTitle">{{ item.label | translate }}</li>

          <li *ngIf="!item.isTitle && !item.isLayout" [ngClass]="{'mm-active': item === selectedMenu}">
            <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent"
               [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
              <span class="menu-item"> {{ item.label | translate }}</span>
              <span class="badge rounded-pill float-end bg-{{item.badge.variant}}" *ngIf="item.badge">
                {{item.badge.text| translate}}
              </span>
            </a>
            <a [routerLink]="item.link" *ngIf="!hasItems(item)" (click)="menuClick(item)" class="side-nav-link-ref" [ngClass]="{'active': item === selectedMenu}" routerLinkActive="active">
              <font-awesome [icon]="'me-1 nav-icon '+fontAwesomeType+' '+item.icon"></font-awesome>
            </a>
          </li>

        </ng-container>

      </ul>
    </div>
    <!-- Sidebar -->
  </ngx-simplebar>
  <!-- Sidebar -->

</div>
<!-- Left Sidebar End -->
