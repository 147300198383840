import { Injectable } from '@angular/core';
import { AuthService } from '@core/auth/auth.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { filter, finalize, map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { FileUploader } from 'ng2-file-upload';
import { Observable, Subject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import Utils from '@shared/utils/utils';
import { Router } from '@angular/router';
import { ApiServer } from '@shared/apis/servers';
import { saveAs as importedSaveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  private baseUrl = environment.api.backend;

  uploader: FileUploader;

  uploaderResponse$ = new Subject();
  onAfterAddingFile$ = new Subject();

  bsModalRef: BsModalRef;

  constructor(private authService: AuthService,
              protected http: HttpClient,
              private router: Router,
              protected sanitizer: DomSanitizer,
              private modalService: BsModalService,
              private apiServer: ApiServer,
              private spinnerService: SpinnerService) {
  }

  fileUrlSecure(url) {
    this.spinnerService.start();

    return this.http
      .get(url, { responseType: 'blob' })
      .pipe(
        finalize(() => this.spinnerService.stop()),
        map(blob => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob)))
      );
  }

  downloadFile = (file, filename) => {
    this.fileUrlSecure(file)
      .subscribe((res: any) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = res.changingThisBreaksApplicationSecurity;
        downloadLink.download = filename;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      });
  };

  excelUploader(templateId: string, msgTitle: string, opt: any = {}) {
    const url = `${this.baseUrl}/upload/${templateId}`;

    this.setFileUploader(url, msgTitle, opt);
  }

  setFileUploader(url: string, msgTitle: string, opt: any = {}) {
    this.uploader = new FileUploader({
      url,
      autoUpload: false,
      method: 'POST',
      removeAfterUpload: true
    });

    if (!Utils.isNullObj(opt)) {
      this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
        form.append('data', JSON.stringify(opt));
      };
    }

    const token = this.authService.getToken()?.token;
    const currentCompany = this.authService.getCurrentCompany();

    this.uploader.onAfterAddingFile = file => {
      file.headers = [
        { name: 'Authorization', value: `Bearer ${token.access_token}` },
        { name: 'Company', value: currentCompany },
      ];
      file.withCredentials = true;
      this.onAfterAddingFile$.next(true);
    };

    this.uploader.onProgressItem = (progress: any) => {
      this.spinnerService.start();
    };

    this.uploader.response.subscribe(response => {
      this.spinnerService.stop();
      if (response !== '') {
        const res = JSON.parse(response);
        const msg = res.messages || res.message || 'Success';
        Utils.showMessageDialog(this.modalService, msg).then(() => {
          this.uploaderResponse$.next(res);
        });
      }
    });

    // TODO Check
    this.uploader.onErrorItem = (item, response, status, headers) => {
      const res = JSON.parse(response);

      // let message = res.message;
      if (res.status === 401) {
        this.router.navigate(['/']);
      }
      // if (res.statusCode === 400 && res.message) {
      //   message = res.message;
      // }
      // Utils.showMessageDialog(this.modalService, message);
    };
  }

  getExcelTemplate(templateId: string, queryString?: any) {
    this.saveExcelFile(
      this.apiServer.master.getTemplate(templateId),
      templateId,
    );
  }

  private saveExcelFile(observable: Observable<ArrayBuffer>, filename, contentType = null) {
    if (!filename) {
      filename = 'download';
    }
    if (filename.indexOf('.') < 0) {
      filename += '.xlsx';
    }

    observable.subscribe(res => {
      const data = new Blob([res], { type: contentType || 'application/vnd.ms-excel' });
      importedSaveAs(data, filename);
    }, err => {
      Utils.showErrorDialog(this.modalService, err);
    });
  }
}
