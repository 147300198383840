<ng-container *ngIf="!isSupport; else support">
  <div id="layout-wrapper">
    <!-- Top Bar -->
    <app-topbar (mobileMenuButtonClicked)="onToggleMobileMenu()" (settingsButtonClicked)="onSettingsButtonClicked()">
    </app-topbar>
    <!-- Side Bar -->
    <app-sidebar></app-sidebar>
    <div class="main-content">
      <div class="page-content">
        <div class="container-fluid p-0">
          <!-- Main Content -->
          <router-outlet></router-outlet>
        </div>
      </div>
      <!-- Footer -->
    </div>
  </div>
</ng-container>

<ng-template #support>
  <router-outlet></router-outlet>
</ng-template>
<ngx-spinner name="main-spinner" size="default" [fullScreen]="true" type="line-spin-clockwise-fade"></ngx-spinner>

