import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { routes } from './routes';
import { SharedModule } from '@shared/shared.module';
import { ComponentsModule } from '@components/components.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forRoot(routes),
    ComponentsModule
  ],
  exports: [
    RouterModule,
  ],
  declarations: [
  ]
})

export class RoutesModule {
}
