<div class="detail-container">
  <div class="row">
    <div class="col-2 detail-label">Order #</div>
    <div class="col-4 detail-value">{{orderInfo?.orderId}}</div>
    <div class="col-2 detail-label">Order Date</div>
    <div class="col-4 detail-value">{{orderInfo?.orderDate | hqDate}}</div>
  </div>

  <div class="row">
    <div class="col-2 detail-label">Order Status</div>
    <div class="col-4 detail-value">
      <badge class="mr-8px text-uppercase"
             [text]="orderInfo?.orderStatus"
             [color]="color.grayDefault"
             [size]="'wd-auto'"></badge>
    </div>
    <div class="col-2 detail-label">Order Amount</div>
    <div class="col-4 detail-value">
        {{ orderInfo?.amount | currency }}
    </div>
  </div>

  <div class="row">
    <div class="col-2 detail-label">Product</div>
    <div class="col-4 detail-value">
      <div class="d-flex">
        <badge class="mr-8px text-uppercase"
               [text]="orderInfo?.productCode"
               [color]="color.grayDefault"
               [size]="'wd-auto'"></badge>
        <div>{{orderInfo?.product}}</div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-2 detail-label">History</div>
    <div class="col-8 detail-value h-auto">
      <div class="d-flex mb-8px" *ngFor="let history of orderInfo?.history">
        <badge class="mr-8px text-uppercase"
               [text]="history?.status"
               [color]="color.grayDefault"
               [size]="'wd-auto'"></badge>
        <div>{{history?.date | hqDateTime}}</div>
      </div>
    </div>
  </div>
</div>
