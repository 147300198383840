<div class="detail-container h-100">
  <div class="row border-dashed mx-0 h-30px">
    <div class="col-1 col-title">No.</div>
    <div class="col-2 col-title">Date</div>
    <div class="col-1 col-title">Status</div>
    <div class="col-8 col-title">Manager</div>
  </div>
  <div class="row mx-0 mt-12px py-10px" *ngFor="let history of historyInfo; let i = index; let last = last;"
       [ngClass]="{'border-dashed': !last}">
    <div class="col-1 col-value">{{ historyInfo?.length - i }}</div>
    <div class="col-2 col-value">{{ history?.date | hqDateTime }}</div>
    <div class="col-1 col-value">
      <badge class="mr-8px" [text]="history?.status.toUpperCase()"
             [color]="color.grayDefault" [size]="'wd-auto'"></badge>
    </div>

    <div class="col-8 col-value">
      <ng-container *ngIf="history?.status === 'OPEN'; else isBatch">
        {{ history?.user | displayName }}
      </ng-container>
      <ng-template #isBatch>
        {{ (history?.user | displayName) || 'By daily batch issuer' }}
      </ng-template>
    </div>
  </div>
</div>

