import { Component, Input } from '@angular/core';

@Component({
  selector: 'field-error',
  templateUrl: './field-error.component.html',
  styleUrls: ['./field-error.component.scss']
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class DpFieldError {
  @Input() formField;
  @Input('error')
  set errorMessages(value) {
    if (value) {
      this.messages = value;
    }
  }
  messages = ['This is required field'];

}
