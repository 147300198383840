import { Injectable } from '@angular/core';
import { BaseBackend } from '@shared/apis/servers/base-backend';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TicketBackendServer extends BaseBackend {
  getTicket(ticketNo, company): Observable<any> {
    return this.get(`/ticket/${ticketNo}?company=${company}`);
  }

  resendMessage(params): Observable<any> {
    const { messageId } = params;
    return this.post(`/ticket/resend/${messageId}`, params);
  }

  offerReissue(ticketNo): Observable<any> {
    return this.post(`/ticket/offer-reissue/${ticketNo}`, null);
  }
}
