import { Pipe, PipeTransform } from '@angular/core';
import DateUtils from '@shared/utils/date-utils';

@Pipe({ name: 'hqDateTimeFormat' })
export class HqDateTimeFormatPipe implements PipeTransform {

  transform(val: any, format: any): any {
    return DateUtils.toHqMomentFormat(val, format);
  }
}
