import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseBackend } from '@shared/apis/servers/base-backend';

@Injectable({
  providedIn: 'root'
})
export class AuthBackendServer extends BaseBackend {

  login(data): Observable<any> {
    return this.post(`/auth/login`, data);
  }

  refreshToken(refreshToken): Observable<any> {
    const query = {
      refreshToken,
    };
    return this.post('/auth/refresh-token', query);
  }

  requestCode(email: string): Observable<Object> {
    const query = {
      email: email
    };
    return this.post('/auth/reset-code/send', query);
  }

  validateCode(email: string, code: string): Observable<Object> {
    const query = {
      email: email,
      resetCode: code.toString()
    };
    return this.post('/auth/reset-code/validate', query);
  }

  changePassword(email: string, code: string, password: string): Observable<Object> {
    const query = {
      email: email,
      resetCode: code.toString(),
      password: password
    };
    return this.post('/auth/reset-code/change-password', query);
  }

  otpLogin(username: string, code: string, tfaToken: string, trust: boolean): Observable<any> {
    const query = {
      username,
      code,
      tfaToken,
      trust,
    };
    return this.post('/auth/otp', query);
  }

  resendOtp(username: string, tfaToken: string): Observable<any> {
    const query = {
      username,
      tfaToken,
    };
    return this.post('/auth/otp/send', query);
  }

  options(params): Observable<any> {
    const { ticketNo } = params;
    return this.get(`/auth/verification-code/options?ticketNo=${ticketNo}`);
  }

  request(params): Observable<any> {
    return this.post(`/auth/verification-code/request`, params);
  }

  validate(params): Observable<any> {
    return this.post(`/auth/verification-code/validate`, params);
  }

  // region MFA
  getQRCode(): Observable<any> {
    const query = {};
    return this.post('/auth/mfa/qrcode', query);
  }

  register(code: string): Observable<any> {
    const query = {
      code,
    };
    return this.post('/auth/mfa/register', query);
  }

  mfaLogin(username: string, code: string, tfaToken: string, trust?: boolean): Observable<any> {
    const query = {
      username,
      code,
      tfaToken,
      trust,
    };
    return this.post('/auth/mfa', query);
  }
  // endregion
}
