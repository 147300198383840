import Swal from 'sweetalert2';
import * as moment from 'moment-timezone';
import { MessageComponent } from '@routes/dialogs/message/message.component';

export default class Utils {

  static showError(err, title?, cb?) {
    if (!err) {
      return;
    }
    if (typeof err !== 'object') {
      err = {
        message: err.toString(),
      };
    }

    const statusCode = err.status || err.error?.statusCode;

    const message = err.error?.message
      || err.message
      || (statusCode ? `ERROR ${ statusCode }` : 'Unknown Error');

    let errorFieldsName = '';

    const errorFields = err.error?.payload?.errorFields;
    if (errorFields && errorFields.length) {
      errorFieldsName = errorFields.filter(f => f.field).map(m => {
        return m.field
      });
      errorFieldsName = ' ('.concat(errorFieldsName).concat(')');
    }

    const errMsg = err.error?.payload?.ErrMsg;
    if (errMsg) {
      errorFieldsName = ' ('.concat(errMsg).concat(')');
    }

    Swal.fire({
      title,
      text: message.concat(errorFieldsName),
      icon: 'error',
      allowOutsideClick: false,
    }).then((result) => {
      if (cb) {
        cb(result, statusCode);
      }
    });
  }

  static showMessageDialog(modalService, message, confirmButton?, title?, cb?) {
    return new Promise(resolve => {
      if (!message) {
        return;
      }

      if (typeof message !== 'object') {
        message = {
          message: message.toString(),
        };
      }

      const statusCode = message.status || message.error?.statusCode;

      const errMsg = message.error?.message
          || message.message
          || (statusCode ? `ERROR ${ statusCode }` : 'Unknown Error');

      let errorFieldsName = '';

      const errorFields = message.error?.payload?.errorFields;
      if (errorFields && errorFields.length) {
        errorFieldsName = errorFields.filter(f => f.field).map(m => {
          return m.field
        });
        errorFieldsName = ' ('.concat(errorFieldsName).concat(')');
      }

      const errMsg2= message.error?.payload?.ErrMsg;
      if (errMsg2) {
        errorFieldsName = ' ('.concat(errMsg2).concat(')');
      }


      const config: any = {
        initialState: {
          message: Utils.isEmpty(errorFieldsName) ? errMsg : errMsg.concat(errorFieldsName),
          confirmButton: confirmButton || 'OK',
          showCancel: false,
        },
        class: 'modal-md modal-dialog-centered',
      };

      if (title) {
        config.initialState.title = title;
      }

      modalService.show(MessageComponent, config).content.onClose.subscribe(res => {
        if (cb) {
          cb(res);
        }
        resolve(res);
      });
    });
  }


  static showConfirmDialog(modalService, message, confirmButton?, title?, cancelButton?, cb?) {
    return new Promise(resolve => {
      if (!message) {
        return;
      }

      const config: any = {
        initialState: {
          message,
          confirmButton: confirmButton || 'OK',
          cancelButton: cancelButton || 'Cancel',
          showCancel: true,
        },
        class: 'modal-md modal-dialog-centered',
      };

      if (title) {
        config.initialState.title = title;
      }

      modalService.show(MessageComponent, config).content.onClose.subscribe(res => {
        if (cb) {
          cb(res);
        }
        resolve(res);
      });
    });
  }

  static showErrorDialog(modalService, err, confirmButton?, title?, cb?) {
    return new Promise(resolve => {
      if (!err) {
        return;
      }

      const initialState: any = {
        title: 'Error',
      };

      if (typeof err === 'string') {
        initialState.message = err;
      } else {
        if (typeof err !== 'object') {
          err = {
            message: err.toString(),
          };
        }

        const statusCode = err.status || err.error?.statusCode;

        initialState.message = err.error?.message
          || err.message
          || (statusCode ? `ERROR ${ statusCode }` : 'Unknown Error');
      }

      if (confirmButton) {
        initialState.confirmButton = confirmButton;
      }

      if (title) {
        initialState.title = title;
      }

      modalService.show(MessageComponent, {
        initialState,
        class: 'modal-md'
      }).content.onClose.subscribe(() => {
        if (cb) {
          cb();
        }
        resolve(null);
      });
    });
  }

  static getErrorMessage(err, defaultMessage = 'Error while processing...') {
    let message = defaultMessage;
    if (err.error) {
      if (err.error.messages) {
        message = err.error.messages.join('\n');
      } else if (err.error.message) {
        message = err.error.message;
      }
    } else if (err.message) {
      message = err.message;
    } else if (err.messages) {
      message = err.messages.join('\n');
    }

    return message;
  }

  static displayName(data) {
    if (!data) {
      return '';
    }

    const { firstName, lastName, username } = data;

    if (!(firstName && lastName)) {
      return username;
    }

    const user = [];
    if (firstName) {
      user.push(firstName);
    }

    if (lastName) {
      user.push(lastName);
    }

    return user.join(' ');
  }

  static truncate(value: string, args: string[]) {
    const limit = args.length > 0 ? parseInt(args[0], 10) : 20;
    const trail = args.length > 1 ? args[1] : '...';
    return value.length > limit ? value.substring(0, limit) + trail : value;
  }

  static isEmpty(obj) {
    if (typeof obj === 'undefined' || obj === null) {
      return true;
    }

    if (obj.constructor === {}.constructor) {
      return Object.keys(obj).map(x => obj[x]).every(x => typeof x === 'undefined' || x === null);
    }

    if (obj.constructor === [].constructor) {
      return obj.every(x => typeof x === 'undefined' || x === null);
    }

    return false;
  }

  static trimObject(obj) {
    if (!obj) {
      return null;
    }

    return Object.keys(obj).reduce((ret, key) => {
      const value = obj[key];
      if (typeof value === 'undefined' || value === null) {
        return ret;
      }

      if (value.constructor === {}.constructor) {
        if (!Object.keys(value)) {
          return ret;
        }
      }

      if (value.constructor === [].constructor) {
        if (!value.length) {
          return ret;
        }
      }
      ret[key] = value;
      return ret;
    }, {});
  }

  static isNullObj(obj): boolean {
    let isNull = true;
    Object.keys(obj).forEach(key => {
      if (obj[key]) {
        isNull = false;
      }
    });
    return isNull;
  }

  static ticketNo(ticketNo) {
    if (!ticketNo) {
      return '';
    }

    const index = ticketNo.length - 5;

    return (
      '<span style="white-space:nowrap">' +
      ticketNo.substring(0, index) +
      '<span class="font-weight-bold" style="color:black">' +
      ticketNo.substring(index) +
      '</span></span>'
    );
  }

  static chunkArray(myArray, chunk_size) {
    let index = 0;
    const arrayLength = myArray.length;
    let tempArray = [];

    for (index = 0; index < arrayLength; index += chunk_size) {
      let myChunk = myArray.slice(index, index + chunk_size);
      // Do something if you want with the group
      tempArray.push(myChunk);
    }

    return tempArray;
  }

  static tel(tel) {
    if (!tel) {
      return '';
    }

    tel = Utils.regExp(tel);

    const value = tel.toString().trim().replace(/\D/g, '');

    if (value.match(/[^0-9]/)) {
      return tel;
    }

    let country;
    let city;
    let num;

    switch (value.length) {
      case 10: // +1PPP####### -> C (PPP) ###-####
        country = 1;
        city = value.slice(0, 3);
        num = value.slice(3);
        break;

      case 11: // +CPPP####### -> CCC (PP) ###-####
        country = value[0];
        city = value.slice(1, 4);
        num = value.slice(4);
        break;

      case 12: // +CCCPP####### -> CCC (PP) ###-####
        country = value.slice(0, 3);
        city = value.slice(3, 5);
        num = value.slice(5);
        break;

      default:
        return tel;
    }

    /*if (country === 1) {
      country = '';
    }*/

    num = num.slice(0, 3) + '-' + num.slice(3);

    return ('+' + country + ' (' + city + ') ' + num).trim();
  }

  static regExp(value) {
    if (!value) {
      return value;
    }
    const regExp = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;
    return value.replace(regExp, '').replace(/ /gi, '');
  }

  static phoneNumberExp(value) {
    if (!value) {
      return value;
    }
    const regExp = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;
    const replace = value.replace(regExp, '').replace(/ /gi, '');
    if (replace.length === 11) {
      return replace.substring(1);
    }
    return replace;
  }

  static filter(value: any, searchText: string, property: any = {}) {
    const re = new RegExp(searchText, 'gi');

    let replaceValue;
    if (property.isBold) {
      replaceValue = `<span class="search-text-bold">$&</span>`;
    } else if (property.isFind) {
      replaceValue = `<span class="search-text mark-${ searchText }">$&</span>`;
    } else {
      replaceValue = `<span class="search-text">$&</span>`;
    }
    return value.replace(re, replaceValue);
  }

  static tat(date, toDate?) {
    if (!date) {
      return null;
    }

    if (!moment(date).isValid) {
      return null;
    }

    const start = new Date(date);
    if (!toDate) {
      toDate = new Date();
    } else {
      toDate = new Date(toDate);
    }

    return this.daysBetween(start, toDate);
  }

  static tatClass(tatOrDate) {
    if (tatOrDate === null) {
      return 'tat-black';
    }

    let tat = null;
    if (isNaN(tatOrDate)) {
      tat = this.tat(tatOrDate);
    } else {
      tat = tatOrDate;
    }

    if (tat <= 7) {
      return 'tat-green';
    } else if (tat <= 14) {
      return 'tat-yellow';
    } else if (tat <= 20) {
      return 'tat-red';
    } else {
      return 'tat-black';
    }
  }

  static daysBetween(date1, date2) {
    const start = Math.floor(date1.getTime() / (3600 * 24 * 1000)); // days as integer from..
    const end = Math.floor(date2.getTime() / (3600 * 24 * 1000)); // days as integer from..
    return end - start; // exact dates
  }

  static text2Html(text) {
    if (typeof text !== 'string') {
      return text;
    }
    const rules = [{ regex: /\n/g, replace: '<br />' }];

    for (let i = 0; i < rules.length; i++) {
      const rule = rules[i];
      if (text.match(rule.regex)) {
        text = text.replace(rule.regex, rule.replace);
        break;
      }
    }
    return text;
  }

}
