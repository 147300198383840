import { Injectable } from '@angular/core';
import { BaseBackend } from '@shared/apis/servers/base-backend';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserBackendServer extends BaseBackend {

  getUsers(builder): Observable<any> {
    return this.get('/user', { params: builder });
  }

  getUser(username): Observable<any> {
    return this.get(`/user/${username}`);
  }

  saveUser(user): Observable<any> {
    const { username } = user;
    if (user._id) {
      return this.patch(`/user/${username}`, user);
    }
    return this.post('/user', user);
  }

}
