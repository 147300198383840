import { Injectable } from '@angular/core';
import { BaseBackend } from '@shared/apis/servers/base-backend';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OfferBackendServer extends BaseBackend {

  getOffers(builder): Observable<any> {
    return this.get('/offer', { params: builder });
  }

  getOffer(offerId): Observable<any> {
    return this.get(`/offer/${offerId}`);
  }

  saveOffer(data): Observable<any> {
    const { offerId } = data;
    return this.patch(`/offer/${offerId}`, data);
  }
}
