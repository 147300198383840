import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@core/auth/auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private router: Router,
              private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.checkSignIn(state.url);
  }

  checkSignIn(url): boolean {
    const signIn = '/login';

    if (!this.authService.getRoles().length) {
      this.router.navigate([`/auth${ signIn }`]);
      return false;
    }

    if (this.authService.isAuthenticated() || [signIn, '/hb'].some(x => url.startsWith(x))) {
      return true;
    }

    this.authService.redirectUrl = url;
    this.router.navigate([`/auth${ signIn }`]);
    return false;
  }
}
