import { Component, Input, OnInit } from '@angular/core';
import { SpinnerService } from '@shared/services/spinner.service';
import { ApiService } from '@shared/apis';
import { finalize } from 'rxjs/operators';
import Utils from '@shared/utils/utils';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent {

  @Input() downUrl: string;
  @Input() fileType: string = 'excel';
  @Input() query: any;
  @Input() q: any;
  @Input() params: any;
  @Input() title: any = 'Export';

  constructor(private spinnerService: SpinnerService,
              private apiService: ApiService,
              private modalService: BsModalService) {

  }

  onClickExport() {
    const fileType = this.fileType;
    let downUrl = this.downUrl;


    if (downUrl.startsWith('/')) {
      downUrl = downUrl.substring(1);
    }

    this.spinnerService.start();
    this.apiService.master.fileDownload(downUrl, fileType, this.query, this.q, this.params)
      .pipe(finalize(() => this.spinnerService.stop()))
      .subscribe(res => {
        const message = res.messages ? res.messages[0] : 'Data will be emailed to you shortly.';
        Utils.showMessageDialog(this.modalService, message, 'OK', 'Export');
      });
  }
}
