import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'doDefault' })
export class SelectDefaultPipe implements PipeTransform {
  transform(value, defaultValue?: any) {
    if (!value) {
      return;
    }

    if (defaultValue) {
      return value.find(f => f.name === defaultValue) ? value.find(f => f.name === defaultValue).code : [];
    }

    return value.length ? value[0].code : [];
  }
}
