import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import Configs from '@shared/constants/configs';
import * as _ from 'lodash-es';
import { PagedResponse } from '@shared/models/paged-response';
import { PageEvent } from '@shared/models/page-event';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as SimpleBar from 'simplebar';
import { Constants } from '@shared/constants/constants';
import { SpinnerService } from '@shared/services/spinner.service';

@Component({
  selector: 'list-template',
  templateUrl: './list-template.component.html',
  styleUrls: ['./list-template.component.scss']
})
export class ListTemplateComponent implements OnInit, OnDestroy {

  readonly configs = Configs;
  readonly sizeOptions = Configs.pagination.sizeOptions;

  totalItems = null;
  currentPage = 0;
  totalPages = 0;

  pageForm: FormGroup;
  defaultPagination = _.cloneDeep(this.configs.pagination);
  itemsPerPage = this.defaultPagination.defaultSize;
  pageSizeOptions: Array<number> = Configs.pagination.sizeOptions;

  pagination = false;

  boundHeaderClickEvent = false;

  isApply = false;

  private sortOrders = [];

  @Input() less: any;
  @Input() componentId: any;
  @Input() maxSize: number;
  @Input() showFilter = false;
  @Input() showGrid = true;
  @Input() showQ = false;

  @Input() placeHolderForQ = 'Search...';

  @Input()
  set pagedResponse(pagedResponse: PagedResponse) {
    if (!pagedResponse) {
      this.pagination = false;
      return;
    }

    this.pagination = true;
    this.totalItems = pagedResponse.totalDocs || pagedResponse.total || _.get(pagedResponse, 'docs', []).length;
    this.totalPages = pagedResponse.totalPages || pagedResponse.pages || 1;
    this.currentPage = pagedResponse.page || this.defaultPagination.startPage;
    this.itemsPerPage = pagedResponse.limit || this.defaultPagination.defaultSize;

    if (!pagedResponse.sort) {
      this.bindTableHeaderSortRemove();
    }

    if (!this.boundHeaderClickEvent) {
      this.bindTableHeaderClickEvent();
    }

    this.pageForm.patchValue({
      page: this.currentPage,
      pageSize: this.itemsPerPage,
    });

    this.cdRef.detectChanges();
    // const elementById = document.getElementById('ngxSimplebar');
    // if (elementById) {
    //   const el = new SimpleBar(elementById);
    //   el.getScrollElement().scrollTop;
    // }
  }

  @Output() pageEvent = new EventEmitter<PageEvent>();
  @Output() toggleDetail = new EventEmitter();
  @Output() openFilter = new EventEmitter();

  @Output() search = new EventEmitter();

  @ViewChild('listContainer') private listContainer;
  @ViewChild('pop') private pop;

  @Input() selectedItem: any;

  private _showDetail = false;

  @Input()
  set showDetail(showDetail: boolean) {
    this._showDetail = showDetail;
    this.isShowDetail = showDetail;
    this.isNotShowDetail = !showDetail;
  }

  get showDetail() {
    return this._showDetail;
  }

  @HostBinding('class') clazz = 'd-flex';
  // @HostBinding('class.col-6') @Input() isHalfWidth: boolean;
  @HostBinding('class.table-less') isShowDetail: boolean;
  @HostBinding('class.w-100') isNotShowDetail: boolean;

  constructor(private fb: FormBuilder,
              private cdRef: ChangeDetectorRef,
              public spinnerService: SpinnerService) {
    this.pageForm = this.fb.group({
      page: this.currentPage,
      pageSize: Configs.pagination.defaultSize,
    });
  }

  ngOnInit() {
    this.setDefaultWidth();
  }

  setDefaultWidth() {
    if (!this.isShowDetail && !this.isNotShowDetail) {
      this.isNotShowDetail = true;
    }
  }

  private bindTableHeaderSortRemove() {
    if (!this.listContainer) {
      return;
    }

    const headers = this.listContainer.nativeElement.querySelectorAll(
      'thead th'
    );

    Array.from(headers).forEach((h: any) => {
      const sf = h.getAttribute('data-sort-fields');
      if (!sf) {
        return;
      }

      // remove not sorted
      if (h.classList.contains('asc')) {
        h.classList.remove('asc');
      }
      if (h.classList.contains('desc')) {
        h.classList.remove('desc');
      }
    });
    this.sortOrders = [];
  }

  private bindTableHeaderClickEvent() {

    setTimeout(() => {
      const headers = this.listContainer.nativeElement.querySelectorAll(
        'thead th'
      );

      Array.from(headers).forEach(header => {
        // @ts-ignore
        header.addEventListener('click', e => {
          if (this.totalItems === 0) {
            return;
          }


          const target = e.target;
          const sortFields = target.dataset.sortFields;
          this.changeSortOrders(sortFields);

          // update icons
          Array.from(headers).forEach((h: any) => {
            const sf = h.getAttribute('data-sort-fields');
            if (!sf) {
              return;
            }

            let found = false;
            this.sortOrders.forEach(so => {
              if (so.fields.join(',') === sf.replace(/\s/, '')) {
                found = true;
                if (so.order === 'asc') {
                  // sort by asc
                  if (h.classList.contains('desc')) {
                    h.classList.remove('desc');
                  }
                  if (!h.classList.contains('asc')) {
                    h.classList.add('asc');
                  }
                } else {
                  // sort by desc
                  if (h.classList.contains('asc')) {
                    h.classList.remove('asc');
                  }
                  if (!h.classList.contains('desc')) {
                    h.classList.add('desc');
                  }
                }
              }
            });

            if (!found) {
              // remove not sorted
              if (h.classList.contains('asc')) {
                h.classList.remove('asc');
              }
              if (h.classList.contains('desc')) {
                h.classList.remove('desc');
              }
            }
          });
        });
      });

      this.boundHeaderClickEvent = true;
    }, 500);
  }

  private changeSortOrders(sortFields) {
    // sort logic (1st) -> asc (2nd) -> desc (3rd) -> no sort
    if (!sortFields) {
      return;
    }

    let founded = false;
    const orders = [];

    this.sortOrders.forEach(sortOrder => {
      const fields = sortOrder.fields.join(',');
      if (fields === sortFields.replace(/\s/, '')) {
        founded = true;
        if (sortOrder.order === 'asc') {
          sortOrder.order = 'desc';
          orders.push(sortOrder)
        }
      } else {
        orders.push(sortOrder)
      }
    });

    if (!founded) {
      orders.push({
        fields: sortFields.split(',').map(item => item.trim()),
        order: 'asc',
      });
    }
    this.sortOrders = orders;

    const pageEvent = new PageEvent(this.currentPage, this.itemsPerPage);
    pageEvent.sort = this.parseSortOrders();
    this.pageEvent.emit(pageEvent);
  }

  private parseSortOrders() {
    const sorts = [];
    this.sortOrders.forEach(sortOrder => {
      const order = sortOrder.order;
      _.get(sortOrder, 'fields', []).forEach(field => {
        sorts.push((order === 'desc' ? '-' : '') + field);
      });
    });

    return sorts.join(',');
  }

  onPageChanged(event) {
    const { page, itemsPerPage } = event;
    const pageEvent = new PageEvent(page, itemsPerPage);
    pageEvent.sort = this.parseSortOrders();
    if (this.isApply && page !== 2) {
      this.isApply = false;
      return;
    }
    this.pageEvent.emit(pageEvent);
    this.isApply = false;
  }

  toggle() {
    this._showDetail = !this._showDetail;
    this.toggleDetail.emit(this._showDetail);
  }

  ngOnDestroy(): void {
  }

  onPopApplyClick(e) {
    e.preventDefault();
    e.stopPropagation();


    const model = this.pageForm.value;
    const pageSize = model.pageSize;
    let page;

    if (pageSize === this.itemsPerPage) {
      page = model.page > this.totalPages ? this.totalPages : model.page;
    } else {
      page = Configs.pagination.startPage;
    }

    this.currentPage = page;
    this.itemsPerPage = pageSize;

    const pageEvent = new PageEvent(page, pageSize);
    pageEvent.sort = this.parseSortOrders();
    this.isApply = true;
    this.pageEvent.emit(pageEvent);
    this.pop.hide();
  }

  onPopCancelClick(e) {
    e.preventDefault();
    e.stopPropagation();

    this.pageForm.reset({
      page: this.currentPage,
      pageSize: Configs.pagination.defaultSize,
    });

    this.pop.hide();
  }

  onPopFilterClick(e) {
    this.openFilter.emit(true);
  }

  onSearch(e) {
    this.search.emit(e);
  }
}
