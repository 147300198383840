import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListTemplateComponent } from '@components/list-template/list-template.component';
import { DetailTemplateComponent } from '@components/detail-template/detail-template.component';
import { SharedModule } from '@shared/shared.module';
import { DetailInfoComponent } from '@components/detail-info/detail-info.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PageTitleComponent } from '@components/page-title/page-title.component';
import { DpFieldError } from '@components/field-error/field-error.component';
import { FontAwesomeComponent } from './font-awesome/font-awesome.component';
import { NoResultTableComponent } from './no-result-table/no-result-table.component';
import { NoResultComponent } from './no-result/no-result.component';
import { AvatarComponent } from '@components/avatar/avatar.component';
import { BadgeComponent } from '@components/badge/badge.component';
import { ExportComponent } from '@components/export/export.component';
import { DateRangePickerComponent } from '@components/date-range-picker/date-range-picker.component';
import { OfferInfoComponent } from '@components/offer-info/offer-info.component';
import { TicketInfoComponent } from '@components/ticket-info/ticket-info.component';
import { HistoryInfoComponent } from '@components/history-info/history-info.component';
import { RolePermitComponent } from './role-permit/role-permit.component';
import { MobileNotFoundComponent } from './mobile-not-found/mobile-not-found.component';
import { OrderStatusComponent } from './order-status/order-status.component';
import { MessageInfoComponent } from './message-info/message-info.component';

@NgModule({
  declarations: [
    ListTemplateComponent,
    DetailTemplateComponent,
    DetailInfoComponent,
    PageTitleComponent,
    NotFoundComponent,
    DpFieldError,
    FontAwesomeComponent,
    NoResultTableComponent,
    NoResultComponent,
    AvatarComponent,
    BadgeComponent,
    ExportComponent,
    DateRangePickerComponent,
    OfferInfoComponent,
    TicketInfoComponent,
    HistoryInfoComponent,
    RolePermitComponent,
    MobileNotFoundComponent,
    OrderStatusComponent,
    MessageInfoComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [
    ListTemplateComponent,
    DetailTemplateComponent,
    DetailInfoComponent,
    PageTitleComponent,
    NotFoundComponent,
    DpFieldError,
    FontAwesomeComponent,
    NoResultTableComponent,
    NoResultComponent,
    AvatarComponent,
    BadgeComponent,
    ExportComponent,
    DateRangePickerComponent,
    OfferInfoComponent,
    TicketInfoComponent,
    HistoryInfoComponent,
    RolePermitComponent,
    MobileNotFoundComponent,
    OrderStatusComponent,
    MessageInfoComponent,
  ]
})
export class ComponentsModule {
}
