import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { EMPTY, Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { SpinnerService } from '@shared/services/spinner.service';

@Pipe({
  name: 'secure'
})
export class SecurePipe implements PipeTransform {

  constructor(private http: HttpClient,
              private sanitizer: DomSanitizer,
              private spinnerService: SpinnerService) {
  }

  transform(url): Observable<SafeUrl> {
    if (!url) {
      return EMPTY;
    }
    this.spinnerService.start();
    return this.http
      .get(url, { responseType: 'blob' })
      .pipe(
        finalize(() => this.spinnerService.stop()),
        map(blob => {
          return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
        }),
        catchError( err => {
          return EMPTY;
        })
      );
  }

}
