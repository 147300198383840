<header id="page-topbar" [ngClass]="profileName">
  <div class="navbar-header px-0 d-flex flex-column">

    <div class="d-flex justify-content-between w-100">
      <!-- LOGO -->
      <div class="navbar-brand-box px-18px">
        <a class="top-logo logo-light" routerLink="/">
          <span class="logo-sm btn-cursor">
            <img src="/assets/img/logo.svg" width="45px" class="logo">
          </span>
        </a>
      </div>

      <div class="dropdown pr-4px d-lg-none d-md-inline-block">
        <button type="button" class="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn"
                id="page-header-user-dropdown" aria-controls="dropdown-basic"
                (click)="dropDownToggle()">
          <i class="fa fa-fw fa-bars"></i>
        </button>
      </div>

      <!--  user info start    -->
      <div class="dropdown d-inline-block pr-20px d-none d-lg-flex justify-content-end w-100 top-lg" dropdown>
        <button type="button" class="btn header-item user text-start d-flex align-items-center"
                aria-controls="dropdown-basic"
                dropdownToggle>

          <span class="d-none d-sm-block user-item-desc">
            <avatar [naming]="myInfo | displayName" [displayType]="'circle'" [size]="34"></avatar>
          </span>
        </button>
        <div class="dropdown-menu dropdown-menu-end pt-0" style="width: 216px;" *dropdownMenu>
          <div class="p-3 bg-primary border-bottom">
            <h6 class="mb-0 text-white" *ngIf="myInfo.firstName">{{myInfo | displayName}}</h6>
            <p class="mb-0 font-size-11 text-white-50 fw-semibold">{{myInfo.username}}</p>
          </div>
          <a class="dropdown-item" href="javascript: void(0);" (click)="registerMFA()">
            <i class="mdi mdi-qrcode-scan text-muted font-size-16 align-middle me-1"></i>
            <span class="align-middle">Register MFA</span>
          </a>
          <a class="dropdown-item" href="javascript: void(0);" (click)="logout($event)">
            <i class="mdi mdi-logout text-muted font-size-16 align-middle me-1"></i>
            <span class="align-middle">Logout</span>
          </a>
        </div>
      </div>
      <!--  user info end    -->



    </div>
    <div class="w-100"
         *ngIf="isDropDownMenu"
         [delayClickOutsideInit]="true"
         (clickOutside)="topMenuOutSideClick()">
      <div class="dropdown-menu dropdown-menu-end bg-dark pt-0 color-white show w-100">
        <!--<div class="p-3 bg-primary border-bottom">
          <h6 class="mb-0 text-white" *ngIf="myInfo.firstName">{{myInfo | displayName}}</h6>
          <p class="mb-0 font-size-11 text-white-50 fw-semibold">{{myInfo.username}}</p>
        </div>-->

        <ng-container *ngFor="let item of menuItems">
          <a class="dropdown-item" [routerLink]="item.link" *ngIf="!hasItems(item) && item?.isLayout">
            <font-awesome [icon]="'mr-12px nav-icon fa-regular '+item.icon"></font-awesome>
            <span class="align-middle">{{item.label | translate}}</span>
          </a>
        </ng-container>

        <a class="dropdown-item" href="javascript: void(0 );" (click)="logout($event)">
          <i class="mdi mdi-logout font-size-16 align-middle mr-12px"></i>
          <span class="align-middle">Logout</span>
        </a>
      </div>
    </div>

  </div>
</header>
