import { Pipe, PipeTransform } from '@angular/core';
import DateUtils from '@shared/utils/date-utils';

@Pipe({ name: 'localDate' })
export class LocalDatePipe implements PipeTransform {

  transform(val: any): any {
    return DateUtils.localDate(val);
  }

}
