import { Injectable } from '@angular/core';
import { ApiCommon } from '@shared/apis/common';
import { Observable } from 'rxjs';
import { ParamsBuilder } from '@shared/models/params-builder';

@Injectable({
  providedIn: 'root'
})
export class OfferApi extends ApiCommon {

  getOffers(pageEvent, q?: any, query?: any): Observable<any> {
    const builder = new ParamsBuilder();
    builder.pageEvent = pageEvent;
    if (q) {
      builder.q = q;
    }

    if (query) {
      builder.query = query;
    }

    return this.apiServer.offer.getOffers(builder.build());
  }

  getOffer(offerId): Observable<any> {
    return this.apiServer.offer.getOffer(offerId);
  }

  saveOffer(data): Observable<any> {
    return this.apiServer.offer.saveOffer(data);
  }
}
