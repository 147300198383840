import { Injectable } from '@angular/core';
import { ApiCommon } from '@shared/apis/common';
import { Observable } from 'rxjs';
import { ParamsBuilder } from '@shared/models/params-builder';

@Injectable({
  providedIn: 'root'
})
export class ReportApi extends ApiCommon {

  /*getDailyStatus(pageEvent, params): Observable<any> {
    const builder = new ParamsBuilder();
    builder.pageEvent = pageEvent;
    builder.params = params;
    return this.apiServer.report.getDailyStatus(builder.build());
  }*/

  getReports(url, pageEvent, params): Observable<any> {
    const builder = new ParamsBuilder();
    builder.pageEvent = pageEvent;
    builder.params = params;
    return this.apiServer.report.getReports(url, builder.build());
  }
}
