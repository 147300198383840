import { Injectable } from '@angular/core';
import { BaseBackend } from '@shared/apis/servers/base-backend';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyBackendServer extends BaseBackend {

  getCompanies(builder): Observable<any> {
    return this.get('/company', { params: builder });
  }

  getCompany(comapnyId): Observable<any> {
    return this.get(`/company/${comapnyId}`);
  }

  getCompanyCodes(): Observable<any> {
    return this.get('/company/code');
  }

}
