import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogBase } from '@routes/dialogs/dialog.base';
import { FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import Utils from '@shared/utils/utils';

@Component({
  selector: 'app-mfa-register',
  templateUrl: './mfa-register.component.html',
  styleUrls: ['./mfa-register.component.scss']
})
export class MfaRegisterComponent extends DialogBase implements OnInit {
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;

  valForm: FormGroup;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'width': '50px',
      'height': '50px'
    }
  };

  qrCode: string = null;
  code: string = null;
  errorMessage: string = null;

  ngOnInit(): void {
    this.code = null;
    this.setFormControl();
  }

  setFormControl() {
    this.valForm = this.fb.group({});
  }

  onOtpChange(event: string) {
    this.code = event;
  }

  register() {
    this.spinnerService.start('other-layout-spinner');
    this.authService.registerMfa(this.code)
      .pipe(finalize(() => this.spinnerService.stop('other-layout-spinner')))
      .subscribe(result => {
        this.onClose.next(result);
        this.bsModalRef.hide();
      }, err => {
        this.errorMessage = Utils.getErrorMessage(err);
        this.ngOtpInput.setValue(null);
        this.code = null;
      });
  }
}
