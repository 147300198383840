<div class="detail-container h-100">
  <div class="row h-30px border-dashed mx-0">
    <div class="col-6 col-md-1 col-title">No.</div>
    <div class="col-6 col-md-2 col-title">Sent/Schedule*</div>
    <div class="col-6 col-md-1 col-title">Type</div>
    <div class="col-6 col-md-2 col-title">Tel/Email</div>
    <div class="col-6 col-md-3 col-title">Code</div>
    <div class="col-6 col-md-1 col-title">Status</div>
    <div class="col-6 col-md-2 col-title">Resend SMS</div>
  </div>

  <div class="row mx-0 mt-12px" *ngFor="let message of ticketMessages; let i = index; let last = last;"
       [ngClass]="{'border-dashed': !last}">
    <div class="col-md-1 col-value">{{ ticketMessages?.length - i }}</div>
<!--    <div class="col-6 col-md-2 col-value">{{ message?.sentDate ? (message.sentDate | hqDateTime) : (message?.schedule ? message.schedule.concat(' *') : 'N/A') }}</div>-->
    <div class="col-6 col-md-2 col-value">{{ message?.sentDate ? message.sentDate : message?.schedule ? message.schedule.concat(' *') : 'N/A' }}</div>
    <div class="col-6 col-md-1 col-value">{{ message?.messageType }}</div>
    <div class="col-6 col-md-2 col-value">{{ message?.recipient }}</div>
    <div class="col-6 col-md-3 col-value">
      <badge class="mr-8px" *ngIf="message?.event?.name || message?.system"
             [text]="message?.event?.name?.toUpperCase() || message?.system?.toUpperCase()"
             [color]="color.grayDefault" [size]="'wd-auto'"></badge>
    </div>
    <div class="col-6 col-md-1 col-value">
      <badge class="mr-8px" *ngIf="message?.status" [text]="message?.status?.toUpperCase()"
             [color]="color.grayDefault" [size]="'wd-auto'"></badge>
    </div>
    <div class="col-6 col-md-2 col-value">
      <button type="button" class="btn btn-outline-dark btn-badge-outline"
              [disabled]="(message?.status === 'cancel')"
              (click)="resendSMS(message?._id)">
        Resend SMS
      </button>
    </div>
    <div class="col-12 pt-8px pb-12px col-value-message">
      <span [innerHTML]="formatMessage(message?.message)"></span>
    </div>
  </div>

</div>

