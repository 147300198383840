import { Injectable } from '@angular/core';
import { ApiCommon } from '@shared/apis/common';
import { Observable } from 'rxjs';
import {ParamsBuilder} from "@shared/models/params-builder";

@Injectable({
  providedIn: 'root'
})
export class CompanyApi extends ApiCommon {

  getCompanyCodes(): Observable<any> {
    return this.apiServer.company.getCompanyCodes();
  }
}
