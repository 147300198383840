import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService implements OnDestroy {

  isSpinner = false;

  private readonly routesSubscription: Subscription;

  constructor(private spinner: NgxSpinnerService) {
  }

  ngOnDestroy() {
    if (this.routesSubscription) {
      this.routesSubscription.unsubscribe();
    }
  }

  start(name = 'main-spinner') {
    this.isSpinner = !this.isSpinner;
    this.spinner.show(name);
  }

  stop(name = 'main-spinner') {
    this.isSpinner = !this.isSpinner;
   this.spinner.hide(name);
  }

}
