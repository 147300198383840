export enum ComponentId {
  OfferExport = 'OfferExport',
  OfferUpload = 'OfferUpload',
  OfferEdit = 'OfferEdit',
  CouponExport = 'CouponExport',
  CouponUpload = 'CouponUpload',
  CouponEdit = 'CouponEdit',
  FullCode = 'FullCode',
  ForceExpire = 'ForceExpire',
  PlainTextCouponIssue = 'PlainTextCouponIssue',
  AbnormalCaseCouponIssue = 'AbnormalCaseCouponIssue',
  AbnormalCaseReviewed = 'AbnormalCaseReviewed',
  PaymentChange = 'PaymentChange',
  AccessLogDownLoad = 'AccessLogDownLoad',
  ReportExport = 'ReportExport',
  SwitchCoupon = 'SwitchCoupon',
  SurveyExport = 'SurveyExport',

  // ManageOfferCoupon = 'ManageOfferCoupon'
}
