import { Injectable } from '@angular/core';
import { BaseBackend } from '@shared/apis/servers/base-backend';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MobileBackendServer extends BaseBackend {

  getCoupon(params): Observable<any> {
    const { ticketNo }= params;
    return this.get(`/mobile/${ticketNo}`, { params });
  }


}
