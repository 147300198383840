import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'no-result-table',
  templateUrl: './no-result-table.component.html',
  styleUrls: ['./no-result-table.component.scss']
})
export class NoResultTableComponent implements OnInit {

  @Input() type: any;
  @Input() totalItems: number = null;
  @Input() initialMessage = 'Loading...';

  constructor() { }

  ngOnInit(): void {
  }

}
