import { PopupMessage } from '@shared/services/popup-service/models/popupMessage';
import { PopupDialogComponent } from '@shared/services/popup-service/popup-dialog/popup-dialog.component';
import { PopupApiService } from '@shared/services/popup-service/popup-api.service';
import { Injectable } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Injectable({
  providedIn: 'root'
})
export class PopupService {
  constructor(
    private popupApiService: PopupApiService,
    private spinnerService: NgxSpinnerService,
  ) {
  }

  checkPopupMessage(modalService: BsModalService) {
    this.popupApiService.getUserPopup()
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(res => {
        this.showPopupMessage(modalService, res);
      }, err => {
        // suppress error
        console.error(err);
      });
  }


  private showPopupMessage(modalService: BsModalService, popupMessages: PopupMessage[]) {
    const popupMessage = popupMessages.shift();
    if (popupMessage) {
      const config: any = {
        initialState: {
          popupMessage,
        },
        class: `modal-${popupMessage.size || 'lg'}`,
        backdrop: 'static',
        keyboard: false,
      };

      const bsModalRef: BsModalRef = modalService.show(PopupDialogComponent, config);
      bsModalRef.content.onClose.subscribe(() => {
        this.showPopupMessage(modalService, popupMessages);
      });
    }
  }
}
