import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '@env/environment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DialogBase } from '@routes/dialogs/dialog.base';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent extends DialogBase implements OnInit {

  title: any = environment.name;
  message: any;
  confirmButton = 'OK';
  cancelButton = 'OK';
  button = null;
  showCancel = false;

  ngOnInit(): void {
    this.onClose = new Subject();
  }

  confirm() {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }
}
