import { NgModule } from '@angular/core';
import { PopupDialogComponent } from './popup-dialog/popup-dialog.component';
import { PopupApiService } from '@shared/services/popup-service/popup-api.service';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    PopupDialogComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
  ],
  providers: [
    PopupApiService,
  ]
})
export class PopupModule {
}
