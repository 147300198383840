import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'detail-template',
  templateUrl: './detail-template.component.html',
  styleUrls: ['./detail-template.component.scss']
})
export class DetailTemplateComponent implements OnInit {

  @Input() showDetail: boolean = false;

  @HostBinding
  ('class') gridClass = 'd-flex w-100';

  constructor() { }

  ngOnInit(): void {
  }

}
