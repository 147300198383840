import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout/layout.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { TopbarComponent } from './layout/topbar/topbar.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { LanguageService } from '@shared/services/language.service';
import { CoreModule } from '@core/core.module';
import { OtherLayoutComponent } from '@layouts/other-layout/other-layout.component';
import { ComponentsModule } from '@components/components.module';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { MoblieLayoutComponent } from './moblie-layout/moblie-layout.component';

@NgModule({
  declarations: [
    LayoutComponent,
    TopbarComponent,
    SidebarComponent,
    OtherLayoutComponent,
    AuthLayoutComponent,
    MoblieLayoutComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    CoreModule,
    ComponentsModule,
  ],
  providers: [
    LanguageService
  ],
})
export class LayoutsModule { }
