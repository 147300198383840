import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';
import Configs from '../constants/configs';

const momentConstructor = moment;

export default class DateUtils {

  /**
   * Get current date of midnight.
   * @returns {Date}
   */
  static getMidnight(date = new Date()): Date {
    date.setHours(0, 0, 0, 0);

    return date;
  }

  /**
   * Get date of midnight.
   * @param date
   * @returns {Date}
   */
  static getHqMidnight(date): Date {
    return this.getHqMoment(date).toDate();
  }

  static getHqMoment(date?): Moment {
    if (!date) {
      return moment.tz(Configs.hq.timezone);
    }
    return moment.tz(date, Configs.hq.timezone);
  }

  static addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  static toHqMomentWithoutChangingTime(date: Date | string, time?: Date | string, format?): any {
    if (!date) {
      return null;
    }

    if (!format) {
      format = Configs.format.dateTime;
    }

    const dateObj = date instanceof Date ? date : new Date(date);
    const timeObj = time ? (time instanceof Date ? time : new Date(time)) : dateObj;

    const dateFormat = moment(dateObj).format(Configs.format.date);
    const timeFormat = moment(timeObj).format(Configs.format.time);

    return moment.tz( new Date(`${dateFormat} ${timeFormat}`), Configs.hq.timezone).format( format);
  }

  static toUtcMomentWithoutChangingTime(date: Date | string, time: Date | string): Moment {
    if (!date) {
      return null;
    }

    const dateObj = date instanceof Date ? date : new Date(date);
    const timeObj = time ? (time instanceof Date ? time : new Date(time)) : dateObj;

    const dateFormat = moment(dateObj).format(Configs.format.date);
    const timeFormat = moment(timeObj).format(Configs.format.time);

    return moment.utc(`${dateFormat} ${timeFormat}`, Configs.format.dateTime);
  }

  static toLocalMomentWithoutChangingTime(date: Date | string, time?: Date | string, format?): any {
    if (!date) {
      return null;
    }

    if (!format) {
      format = Configs.format.dateTime;
    }

    const dateObj = date instanceof Date ? date : new Date(date);
    const timeObj = time ? (time instanceof Date ? time : new Date(time)) : dateObj;

    const dateFormat = moment(dateObj).format(Configs.format.date);
    const timeFormat = moment(timeObj).format(Configs.format.time);

    return moment( new Date(`${dateFormat} ${timeFormat}`)).format(format);
  }

  static toHqDateTimeFormat(date: Date | string): string {
    return DateUtils.toHqMomentFormat(date, Configs.format.dateTime);
  }

  static toHqMomentFormat(date: Date | string, format: string): string {
    if (!date || !moment(date).isValid) {
      return null;
    }

    return moment(date).tz(Configs.hq.timezone).format(format);
  }

  static localDate(date) {
    if (!date) {
      return null;
    }

    if (!moment(date).isValid) {
      return null;
    }

    return moment(date).format(Configs.format.date);
  }

  static toLocalDateTimeFormat(date: Date | string): string {
    return DateUtils.toLocalMomentFormat(date, Configs.format.dateTime);
  }

  static toLocalMomentFormat(date: Date | string, format: string): string {
    if (!date || !moment(date).isValid) {
      return null;
    }

    return moment(date).format(format);
  }

  static utc2HQDate(date, format?) {
    if (!date) {
      return null;
    }

    if (!moment(date).isValid) {
      return null;
    }

    return moment(date)
      .tz(Configs.hq.timezone)
      .format(format ||Configs.format.date);
  }

  static utc2HQDateTime(datetime) {
    if (!datetime) {
      return null;
    }

    if (!moment(datetime).isValid) {
      return null;
    }

    return moment(datetime)
      .tz(Configs.hq.timezone)
      .format(Configs.format.dateTime);
  }

  static utc2HQDateOptionalTime(datetime) {
    if (!datetime) {
      return null;
    }

    if (!moment(datetime).isValid) {
      return null;
    }

    const hqDateTime = moment(datetime).tz(Configs.hq.timezone);
    if (hqDateTime.minutes() === 0 && hqDateTime.hours() === 0) {
      return hqDateTime.format(Configs.format.date);
    } else {
      return hqDateTime.format(Configs.format.dateTime);
    }
  }

  static dateFormat(value: Date, format) {
    return momentConstructor(value).format(format);
  }
}
