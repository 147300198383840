<div class="modal-header">
  <h4 class="modal-title">
    {{ title }}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <img src="assets/img/ico-xmark.svg" class="btn-icon-32px"  alt=""/>
  </button>
</div>

<div class="modal-body">
  <div class="modal-confirm-text">{{ message }}</div>
</div>

<div class="modal-footer justify-content-end">
  <div class="botton-group">
    <button type="button" class="btn btn-dark ml-12px btn-lg wd-153px" (click)="confirm()">
      <span class="btn-font-primary">{{ button || confirmButton }}</span>
    </button>
    <button type="button" class="btn btn-light ml-12px btn-lg wd-153px" *ngIf="showCancel" (click)="close()">
      <span class="btn-font-primary">{{ cancelButton }}</span>
    </button>
  </div>
</div>
