import { HttpParams } from '@angular/common/http';
import { PageEvent } from '@shared/models/page-event';

export class ParamsBuilder {

  private _params: HttpParams;

  constructor() {
    this._params = new HttpParams();
  }

  set pageEvent(pageEvent: PageEvent) {
    pageEvent = pageEvent ? pageEvent : new PageEvent();

    this._params = this._params.append('page', pageEvent.page.toString());
    this._params = this._params.append('limit', pageEvent.limit.toString());
    if (pageEvent.sort) {
      this._params = this._params.append('sort', pageEvent.sort.toString());
    }
  }

  set page(val: number) {
    this._params = this._params.append('page', val.toString());
  }

  set limit(val: number) {
    this._params = this._params.append('limit', val.toString());
  }

  set selectFields(val: string | string[]) {
    const param = val instanceof Array ? this.convertToString(val) : val;
    this._params = this._params.append('select', param);
  }

  set sort(val: string | string[]) {
    if (val) {
      const param = val instanceof Array ? this.convertToString(val) : val;
      this._params = this._params.append('sort', param);
    }
  }

  // FK 연결 된 데이터 콜
  set populate(val: string | string[]) {
    const param = val instanceof Array ? this.convertToString(val) : val;
    this._params = this._params.append('populate', param);
  }

  // search text
  set q(val: any) {
    if (!val) {
      return;
    }

    this._params = this._params.append('q', val);
  }

  // set username
  set username(val: any) {
    if (!val) {
      return;
    }

    this._params = this._params.append('username', val);
  }


  // Mongo Query
  set query(val: any) {
    if (!val) {
      return;
    }

    const query = JSON.stringify(val);

    this._params = this._params.append('query', query);
  }

  // Mongo Query
  set filter(val: any) {
    if (!val) {
      return;
    }

    const filter = JSON.stringify(val);

    this._params = this._params.append('filter', filter);
  }

  set params(params: any) {
    Object.keys(params).forEach(key => {
      this._params = this._params.append(key, params[key]);
    });
  }

  build(): HttpParams {
    return this._params;
  }

  private convertToString(arr: string[]) {
    return arr.join().replace(/,/g, ' ');
  }

}
