import { Pipe, PipeTransform } from '@angular/core';
import Utils from '@shared/utils/utils';

@Pipe({ name: 'tat' })
export class TatPipe implements PipeTransform {

  transform(fromDate, toDate): any {
    return Utils.tat(fromDate, toDate);
  }

}
