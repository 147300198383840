import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@core/auth/auth.service';

@Injectable()
export class MobileGuardService implements CanActivate {

  constructor(private router: Router,
              private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const params = route.params;
    const queryParams = route.queryParams;

    const { ticketNo, offerGroup } = params;
    const { secret } = queryParams;

    return this.checkSignIn(state.url, ticketNo);
  }

  checkSignIn(url, ticketNo): boolean {
    if (this.authService.isMobileAuthenticated()) {
      return true;
    }
    this.authService.mobileRedirectUrl = url;

    this.router.navigate([`/mobiles/verify/${ticketNo}`]);
    return false;
  }
}
