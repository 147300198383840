import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ComponentsService } from '@components/components.service';
import { debounceTime, distinctUntilChanged, takeWhile } from 'rxjs/operators';
import * as moment from 'moment';
import Configs from '@shared/constants/configs';

const momentConstructor = moment;

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit, OnDestroy {

  @Input() bsConfig = {
    containerClass: 'theme-dk',
    showWeekNumbers: false,
    dateInputFormat: 'MM/DD/YYYY',
  };
  @Input() componentId: any;
  @Input() dateOption: any;
  @Output() returnValues: any = new EventEmitter();

  endBsDate;
  bsStartValue;
  bsEndValue;

  dateRangeForm: FormGroup;

  alive = true;

  constructor(private fb: FormBuilder,
              private componentsService: ComponentsService) {
  }

  ngOnInit(): void {
    this.initConditionForm();

    this.componentsService.selectMultiOptionsInit$
      .pipe(takeWhile(() => this.alive))
      .subscribe(res => {
        if (res.isReset && this.componentId === res.componentId) {
          this.dateRangeForm.reset();
          this.initConditionForm();
        }
      });
  }

  ngOnDestroy() {
    this.alive = false;
  }

  initConditionForm() {
    this.endBsDate = {
      minDate: new Date(moment().subtract(this.dateOption.minDateMonth, 'months').format(this.bsConfig.dateInputFormat)),
      maxDate: new Date(moment().format(this.bsConfig.dateInputFormat)),
    };
    const startDateMonth = this.dateOption.startDateMonth || 1;
    this.bsStartValue = moment().subtract(startDateMonth, 'months').format(this.bsConfig.dateInputFormat);
    this.bsEndValue = new Date(moment().format(Configs.format.date));

    this.dateRangeForm = this.fb.group({
      periodStart: this.bsStartValue,
      periodEnd: this.bsEndValue,
    });
    this.dateRangeForm.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe(() => this.returnValue());
  }

  defaultDateFormat(value: Date) {
    return momentConstructor(value).local().format(this.bsConfig.dateInputFormat);
  }

  openCalendar($event, type) {
    if (!$event) {
      return null;
    }
    type.setValue(this.defaultDateFormat($event));

    if (type === this.dateRangeForm.controls['periodStart']) {
      this.endBsDate.minDate = new Date(moment($event).format(this.bsConfig.dateInputFormat));
      this.endBsDate.maxDate = new Date(moment($event).add(this.dateOption.minDateMonth, 'months').format(this.bsConfig.dateInputFormat));

      const diffMonths = moment($event).diff(this.dateRangeForm.controls['periodEnd'].value, 'months', true);
      setTimeout(() => {
        if (diffMonths > 1) {
          this.dateRangeForm.controls['periodEnd'].setValue(moment().format(Configs.format.date));
        } else if (Math.abs(diffMonths) > this.dateOption.minDateMonth) {
          this.dateRangeForm.controls['periodEnd'].setValue(moment($event).add(this.dateOption.minDateMonth, 'months').format(this.bsConfig.dateInputFormat));
        } else if (diffMonths > 0) {
          this.dateRangeForm.controls['periodEnd'].setValue(moment($event).format(this.bsConfig.dateInputFormat));
        }
      });
      /*setTimeout(() => {
        this.dateRangeForm.controls.periodEnd.setValue(this.endBsDate.maxDate);
      });*/
    }
  }

  returnValue() {
    const setDateFormat = (date, format) => {
      return moment(date, format).isValid() ?
        moment(new Date(date)).format(format) :
        null;
    };

    const value = this.dateRangeForm.value;
    value.periodStart = setDateFormat(value.periodStart, this.bsConfig.dateInputFormat);
    value.periodEnd = setDateFormat(value.periodEnd, this.bsConfig.dateInputFormat);

    this.returnValues.emit(value);
  }
}
