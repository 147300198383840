import { Component, OnInit } from '@angular/core';
import { Location, PopStateEvent } from '@angular/common';
import { Event, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'ecoupon-web';

  constructor(private router: Router,
              private location: Location) {
  }

  ngOnInit(): void {
    this.detectPopState();
  }

  detectPopState() {
    this.location.subscribe((popStateEvent: PopStateEvent) => {

      // Detect popstate
      if (popStateEvent.type === 'popstate') {
        const eventSubscription = this.router.events.subscribe((event: Event) => {
          if (event instanceof NavigationEnd) {
            this.router.navigate(['/']);
            // unsubscribe the subscription of router.events
            eventSubscription.unsubscribe();
          }
        });
      }
    });
  }
}
