import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseBackend } from '@shared/apis/servers/base-backend';
import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { timeout } from 'rxjs/operators';

const urlPrefix = '/master';

@Injectable({
  providedIn: 'root'
})
export class MasterBackendServer extends BaseBackend {

  getConstant(code: any): Observable<any> {
    return this.get(`${urlPrefix}/constant?code=${code}`);
  }

  download(url: string, fileType: string, params): Observable<any> {
    // add full url if start with /
    if (url.charAt(0) === '/') {
      url = `${this.backend}/${url}`;
    }

    url += url.indexOf('?') >= 0 ? '&' : '?';
    url += 'output=' + fileType;

    return this.http.get(url, { params });
  }

  upload(formData): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    const options = {
      headers,
      reportProgress: true,
    };

    // change post for progress bar
    const uploadReq = new HttpRequest('POST', `${this.backend}/upload/file/temp-file`, formData, options);

    return this.http.request(uploadReq).pipe(timeout(500000));
  }

  getTemplate(templateId): Observable<any> {
    const url = `${this.backend}/master/download/${templateId}`
    return this.http.get(url, { responseType: 'arraybuffer' });
  }

  tsvCellsUpload(data: any, code, queryString?: any): Observable<any> {
    let url = `${this.backend}/upload/tsv/${code}`;
    if (queryString) {
      url += `?${queryString}`;
    }
    return this.http.post(url, data);
  }
}
