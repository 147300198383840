import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'detail-info',
  templateUrl: './detail-info.component.html',
  styleUrls: ['./detail-info.component.scss']
})
export class DetailInfoComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  cssClass: string;

  constructor() { }

  ngOnInit(): void {
  }

}
