import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {
  isDesktop = true;

  constructor(private deviceService: DeviceDetectorService) { }

  ngOnInit(): void {
    this.isDesktop = this.deviceService.isDesktop();
  }

}
