import { Pipe, PipeTransform } from '@angular/core';
import Utils from '../utils/utils';

@Pipe({ name: 'displayName' })
export class DisplayNamePipe implements PipeTransform {

  transform(data: any, isNoCustomer = false): any {
    let displayName = Utils.displayName(data) || '';
    if (!displayName && isNoCustomer) {
      displayName = 'No Customer';
    }

    return displayName;
  }

}
