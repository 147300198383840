import { Component, OnInit } from '@angular/core';
import { AuthService } from '@core/auth/auth.service';

@Component({
  selector: 'app-mobile-not-found',
  templateUrl: './mobile-not-found.component.html',
  styleUrls: ['./mobile-not-found.component.scss']
})
export class MobileNotFoundComponent implements OnInit {

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.authService.setMobileToken(null);
  }

}
