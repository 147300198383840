<!--<div class="d-flex justify-content-center" *ngIf="totalItems === null">
  <div class="alert alert-light">
    {{initialMessage}}
  </div>
</div>
-->
<!-- TODO check totalItems null 일때 loading 처리 ? -->

<div class="align-center" *ngIf="totalItems === null || totalItems === 0">
  <font-awesome [icon]="'fa-light fa-file-circle-exclamation'"></font-awesome>
  <span [ngSwitch]="type" class="pl-14px">
    <ng-container *ngSwitchCase="'unable-load-data'">
        Unable to load data
    </ng-container>
    <ng-container *ngSwitchCase="'no-result-found'">
        No results found
    </ng-container>
    <ng-container *ngSwitchCase="'no-data-found'">
        No data found
    </ng-container>
  </span>
</div>
<!--<div class="test-class">
  <font-awesome [icon]="'fa-duotone fa-circle-question font-size-20'"></font-awesome>
  &nbsp;&nbsp;&nbsp;No results found
</div>-->

<!--<div class="test-class">
  <font-awesome [icon]="'fa-duotone fa-file-circle-question font-size-40'"></font-awesome>
  <div>No results found</div>
</div>-->
<!--<i class="fa-duotone fa-file-circle-question"></i>-->
