import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiCommon } from '@shared/apis/common';

@Injectable({
  providedIn: 'root'
})
export class AuthApi extends ApiCommon {

  login(data): Observable<any> {
    return this.apiServer.backend.login(data);
  }

  refreshToken(refreshToken): Observable<any> {
    return this.apiServer.backend.refreshToken(refreshToken);
  }

  resetPassword(email: string): Observable<Object> {
    return this.apiServer.backend.requestCode(email);
  }

  validateCode(email: string, code: string): Observable<Object> {
    return this.apiServer.backend.validateCode(email, code);
  }

  changePassword(email: string, code: string, password: string): Observable<Object> {
    return this.apiServer.backend.changePassword(email, code, password);
  }

  otpLogin(username, code, tfaToken, trust): Observable<boolean> {
    return this.apiServer.backend.otpLogin(username, code, tfaToken, trust);
  }

  resendOtp(username, tfaToken): Observable<boolean> {
    return this.apiServer.backend.resendOtp(username, tfaToken);
  }

  verificationCode(params, handler): Observable<any> {
    return this.apiServer.backend[handler](params);
  }

  // region # MFA
  getQRCode(): Observable<any> {
    return this.apiServer.backend.getQRCode();
  }

  registerMfa(code): Observable<any> {
    return this.apiServer.backend.register(code);
  }

  mfaLogin(username, code, tfaToken, trust?): Observable<any> {
    return this.apiServer.backend.mfaLogin(username, code, tfaToken, trust);
  }
  // endregion
}
