import { Subject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Directive, OnDestroy } from '@angular/core';
import { ApiService } from '@shared/apis';
import { FormBuilder } from '@angular/forms';
import { SpinnerService } from '@shared/services/spinner.service';
import { AuthService } from '@core/auth/auth.service';
import { takeWhile } from 'rxjs/operators';
import {Router} from '@angular/router';
import { FileService } from '@shared/services/file.service';

@Directive()
export class DialogBase implements OnDestroy {
  public onClose: Subject<any> = new Subject();
  protected alive = true;

  constructor(protected bsModalRef: BsModalRef,
              protected bsModalRefSecond: BsModalRef,
              protected fb: FormBuilder,
              protected spinnerService: SpinnerService,
              protected authService: AuthService,
              protected modalService: BsModalService,
              protected apiService: ApiService,
              protected router: Router,
              public fileService: FileService,
    ) {
    this.doInit();
  }

  doInit() {
    this.authService.token$
      .pipe(takeWhile(() => this.alive))
      .subscribe(res => {
        if (!res) {
          this.bsModalRef.hide();
        }
      });
  }

  close(value = null) {
    this.onClose.next(value);
    this.bsModalRef.hide();
  }

  cancel() {
    this.bsModalRef.hide();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}
