export enum ROLE {
  ADMIN = 'ADMIN',
  IT = 'IT',
  BATCH = 'BATCH',
  ENR_AGENT = 'ENR_AGENT',
  ENR_MANAGER = 'ENR_MANAGER',
  ENR_SPECIALIST = 'ENR_SPECIALIST',
  COUPON_MANAGER = 'COUPON_MANAGER',
  REPAIR_OFFER_MANAGER = 'REPAIR_OFFER_MANAGER',
}
