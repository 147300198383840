import { Component, Input, OnInit } from '@angular/core';
import { PopupMessage } from '@shared/services/popup-service/models/popupMessage';
import Utils from '@shared/utils/utils';
import { PopupApiService } from '@shared/services/popup-service/popup-api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { AuthService } from '@core/auth/auth.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'popup-dialog',
  templateUrl: './popup-dialog.component.html',
  styleUrls: ['./popup-dialog.component.scss']
})


export class PopupDialogComponent implements OnInit {
  public onClose: Subject<any> = new Subject();

  popupMessage: PopupMessage;
  agreeCheckBox: boolean = false;

  constructor(
    private bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private authService: AuthService,
    private popupApiService: PopupApiService,
    private spinnerService: NgxSpinnerService,
  ) {
  }


  ngOnInit(): void {
  }

  toHtml(value) {
    return value?.split('\n').join('<br/>') || '';
  }

  checkAgree() {
    this.agreeCheckBox = !this.agreeCheckBox;
  }

  agree(agree) {
    if (!agree) {
      // disagree process
      Utils.showConfirmDialog(this.modalService,'Do you want to sign out?', 'Sign out')
        .then(x => {
          if (x) {
            this.spinnerService.show();
            this.popupApiService.agree(this.popupMessage, false)
              .pipe(finalize(() => this.spinnerService.hide()))
              .subscribe(() => {
                this.onClose.next(this.popupMessage);
                this.bsModalRef.hide();
                this.authService.logout();
              });
          }
        });
      return;
    }

    // agree process
    this.spinnerService.show();
    this.popupApiService.agree(this.popupMessage, true)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(res => {
        this.popupMessage = res;
      });
  }

  hide() {
    // agree process
    this.spinnerService.show();
    this.popupApiService.hide(this.popupMessage, true)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(res => {
        this.popupMessage = res;
        this.onClose.next(this.popupMessage);
        this.bsModalRef.hide();
      });
  }

  dismiss() {
    this.onClose.next(this.popupMessage);
    this.bsModalRef.hide();
  }
}


