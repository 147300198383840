import { ROLE } from '@shared/enums/role';

export default class Roles {

  static readonly admin = [
    ROLE.ADMIN,
  ];

  static readonly couponManager = [
    ROLE.COUPON_MANAGER,
  ]

  static readonly enrManager = [
    ROLE.ENR_MANAGER,
  ]

  static readonly special = [
    ROLE.ENR_SPECIALIST,
  ];

  static readonly agent = [
    ROLE.ENR_AGENT,
  ];

  static readonly repairOfferManager = [
    ROLE.REPAIR_OFFER_MANAGER,
  ];

};
