import { Injectable } from '@angular/core';
import { ApiCommon } from '@shared/apis/common';
import { Observable } from 'rxjs';
import { ParamsBuilder } from '@shared/models/params-builder';

@Injectable({
  providedIn: 'root'
})
export class SurveyApi extends ApiCommon {

  getSurveyResponse(pageEvent, query?: any, params?: any): Observable<any> {
    const builder = new ParamsBuilder();
    builder.pageEvent = pageEvent;

    if (query) {
      builder.query = query;
    }

    if (params) {
      builder.params = params;
    }

    return this.apiServer.survey.getSurveyResponse(builder.build());
  }

}
