<div class="invalid-feedback" *ngIf="formField.errors?.required && formField.touched">
  <ng-container *ngIf="formField.errors?.required">
    <div *ngFor="let m of messages">
      {{m}}
    </div>
  </ng-container>
</div>
<div class="invalid-feedback" *ngIf="formField.errors?.email && formField.touched">
    <span *ngIf="formField.errors?.email">
      This field must be a valid email.
    </span>
</div>
<div class="invalid-feedback" *ngIf="formField.errors?.pattern && formField.touched">
    <span *ngIf="formField.errors?.pattern">
      This field must be a valid character.
    </span>
</div>



