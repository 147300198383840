import { Component, OnInit } from '@angular/core';
import {
  LAYOUT_MODE,
  LAYOUT_POSITION,
  LAYOUT_VERTICAL,
  LAYOUT_WIDTH,
  SIDEBAR_COLOR,
  SIDEBAR_SIZE_SMALL,
  TOPBAR
} from '@shared/models/layouts';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { PopupService } from '@shared/services/popup-service';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  isCondensed = false;

  // layout related config
  layoutType!: string;
  layoutMode!: string;
  layoutwidth!: string;
  layoutposition!: string;
  topbar!: string;
  sidebarcolor!: string;
  sidebarsize!: string;

  isSupport = false;


  constructor(
    private router: Router,
    private authService: AuthService,
    private modalService: BsModalService,
    private popupService: PopupService,
    ) {
    this.isSupport = router.url.includes('support') || router.url.includes('auth');
  }

  ngOnInit(): void {
    document.body.setAttribute('data-layout', 'vertical');

    this.layoutMode = LAYOUT_MODE;
    // default settings
    this.layoutType = LAYOUT_VERTICAL;
    this.layoutwidth = LAYOUT_WIDTH;
    this.layoutposition = LAYOUT_POSITION;
    this.sidebarcolor = SIDEBAR_COLOR;
    this.sidebarsize = SIDEBAR_SIZE_SMALL;
    this.topbar = TOPBAR;

    this.LayoutMode(this.layoutMode);
    this.SidebarColor(this.sidebarcolor);
    this.SidebarSize(this.sidebarsize);
  }

  ngAfterViewInit() {
    if (this.authService.isAuthenticated()) {
      this.popupService.checkPopupMessage(this.modalService);
    }
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle('right-bar-enabled');
  }

  /**
   * On mobiles toggle button clicked
   */
  onToggleMobileMenu() {
    document.body.classList.toggle('sidebar-enable');
    const currentSidebarSize = document.body.getAttribute("data-sidebar-size");
    if (window.screen.width >= 992) {
      if (currentSidebarSize == null) {
        (document.body.getAttribute('data-sidebar-size') == null || document.body.getAttribute('data-sidebar-size') == "lg") ? document.body.setAttribute('data-sidebar-size', 'sm') : document.body.setAttribute('data-sidebar-size', 'lg')
      } else if (currentSidebarSize == "md") {
        (document.body.getAttribute('data-sidebar-size') == "md") ? document.body.setAttribute('data-sidebar-size', 'sm') : document.body.setAttribute('data-sidebar-size', 'md')
      } else {
        (document.body.getAttribute('data-sidebar-size') == "sm") ? document.body.setAttribute('data-sidebar-size', 'lg') : document.body.setAttribute('data-sidebar-size', 'sm')
      }
    }
    this.isCondensed = !this.isCondensed;
  }


  /**
   * Layout Mode Set
   */
  LayoutMode(mode: string) {
    switch (mode) {
      case "light":
        document.body.setAttribute("data-sidebar", "light");
        document.body.setAttribute("data-layout-mode", "light");
        document.body.setAttribute("data-topbar", "light");
        break;
      case "dark":
        document.body.setAttribute("data-sidebar", "dark");
        document.body.setAttribute("data-layout-mode", "dark");
        document.body.setAttribute("data-topbar", "dark");
        break;
      default:
        document.body.setAttribute("data-layout-mode", "light");
        document.body.setAttribute("data-topbar", "light");
        break;
    }
  }

  /**
   * Layout Width Set
   */
  LayoutWidth(width: string) {
    switch (width) {
      case "light":
        document.body.setAttribute("data-layout-size", "fluid");
        break;
      case "boxed":
        document.body.setAttribute("data-layout-size", "boxed");
        break;
      default:
        document.body.setAttribute("data-layout-size", "light");
        break;
    }
  }

  /**
   * Layout Position Set
   */
  LayoutPosition(position: string) {
    if (position === 'fixed') {
      document.body.setAttribute("data-layout-scrollable", "false");
    } else {
      document.body.setAttribute("data-layout-scrollable", "true");
    }
  }

  /**
   * Layout Topbar Set
   */
  Topbar(topbar: string) {
    switch (topbar) {
      case "light":
        document.body.setAttribute("data-topbar", "light");
        break;
      case "dark":
        document.body.setAttribute("data-topbar", "dark");
        break;
      default:
        document.body.setAttribute("data-topbar", "light");
        break;
    }
  }

  /**
   * Layout Sidebar Size Set
   */
  SidebarSize(size: string) {
    switch (size) {
      case "default":
        document.body.setAttribute('data-sidebar-size', 'lg');
        break;
      case "compact":
        document.body.setAttribute('data-sidebar-size', 'md');
        break;
      case "small":
        document.body.setAttribute('data-sidebar-size', 'sm');
        break;
      default:
        document.body.setAttribute('data-sidebar-size', 'lg');
        break;
    }
  }

  /**
   * Layout Sidebar Color Set
   */
  SidebarColor(color: string) {
    switch (color) {
      case "light":
        document.body.setAttribute('data-sidebar', 'light');
        break;
      case "dark":
        document.body.setAttribute("data-sidebar", "dark");
        break;
      case "brand":
        document.body.setAttribute("data-sidebar", "brand");
        break;
      default:
        document.body.setAttribute("data-sidebar", "light");
        break;
    }
  }

}
