import { Injectable } from '@angular/core';
import { BaseBackend } from '@shared/apis/servers/base-backend';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SurveyBackendServer extends BaseBackend {

  getSurveyResponse(builder): Observable<any> {
    return this.get('/survey', { params: builder });
  }
}
