import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class AuthRequestInterceptor implements HttpInterceptor {

  constructor(private injector: Injector) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authService = this.injector.get(AuthService);

    const getToken = request.url.includes('mobile') ?  authService.getMobileToken() : authService.getToken();
    const { token } = getToken || '';

    if (token?.access_token) {
      request = request.clone({
        // withCredentials: true,
        setHeaders: {
          Authorization: `${token.token_type} ${token.access_token}`,
        }
      });
    }

    return next.handle(request);
  }
}
