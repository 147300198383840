import { Injectable } from '@angular/core';
import { AuthBackendServer } from '@shared/apis/servers/auth.backend.server';
import { HttpClient } from '@angular/common/http';
import { MasterBackendServer } from '@shared/apis/servers/master.backend.server';
import { UserBackendServer } from '@shared/apis/servers/user.backend.server';
import { CompanyBackendServer } from '@shared/apis/servers/company.backend.server';
import { OfferBackendServer } from '@shared/apis/servers/offer.backend.server';
import { CouponBackendServer } from '@shared/apis/servers/coupon.backend.server';
import { AbnormalBackendServer } from '@shared/apis/servers/abnormal.backend.server';
import { TicketBackendServer } from '@shared/apis/servers/ticket.backend.server';
import { MobileBackendServer } from '@shared/apis/servers/mobile.backend.server';
import { ReportBackendServer } from '@shared/apis/servers/report.backend.server';
import { SurveyBackendServer } from '@shared/apis/servers/survey.backend.server';

@Injectable({
  providedIn: 'root'
})
export class ApiServer {
  public backend: AuthBackendServer;
  public master: MasterBackendServer;
  public user: UserBackendServer;
  public offer: OfferBackendServer;
  public coupon: CouponBackendServer;
  public abnormal: AbnormalBackendServer;
  public mobile: MobileBackendServer;
  public report: ReportBackendServer;
  public company: CompanyBackendServer;
  public ticket: TicketBackendServer;
  public survey: SurveyBackendServer;

  constructor(private httpClient: HttpClient) {
    this.backend = new AuthBackendServer(httpClient);
    this.master = new MasterBackendServer(httpClient);
    this.user = new UserBackendServer(httpClient);
    this.offer = new OfferBackendServer(httpClient);
    this.coupon = new CouponBackendServer(httpClient);
    this.abnormal = new AbnormalBackendServer(httpClient);
    this.mobile = new MobileBackendServer(httpClient);
    this.report = new ReportBackendServer(httpClient);
    this.company = new CompanyBackendServer(httpClient);
    this.ticket = new TicketBackendServer(httpClient);
    this.survey = new SurveyBackendServer(httpClient);
  }
}
