import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Utils from '@shared/utils/utils';
import Configs from '@shared/constants/configs';
import * as _ from 'lodash-es';
import { SpinnerService } from '@shared/services/spinner.service';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ApiService } from '@shared/apis';
import { finalize } from 'rxjs/operators';
import { values } from 'pusher-js/types/src/core/utils/collections';
import DateUtils from '@shared/utils/date-utils';

interface Exception {
  status: string;
  inDate: string;
  outDate?: string;
  code: string;
  _id: string;
  errorMessage: string;
  description: string;
  note: string;
}

@Component({
  selector: 'app-message-info',
  templateUrl: './message-info.component.html',
  styleUrls: ['./message-info.component.scss']
})
export class MessageInfoComponent implements OnInit {

  @Input() set detailInfo(value) {

    if (!Utils.isEmpty(value) && value.ticket && value.ticket.messages) {
      const { ticket } = value;
      this.ticket = ticket;
      // this.ticketMessages = _.orderBy(ticket.messages, ['date'], ['desc']) || [];
      this.ticketMessages = this.setTimeZone<Exception>(ticket.messages, [
        'sentDate',
        'schedule',
      ]);
    } else {
      this.ticketMessages = [];
    }
  }

  readonly color = Configs.badgeColor;

  ticketMessages: any;
  ticket: any;

  constructor(private spinnerService: SpinnerService,
              private route: ActivatedRoute,
              private modalService: BsModalService,
              private apiService: ApiService) {
  }

  ngOnInit(): void {
    // this.ticketMessages = _.orderBy(this.detailInfo.ticket.messages, ['date'], ['desc']) || [];
  }

  resendSMS(messageId: any) {
    const params = {
      messageId
    }
    this.spinnerService.start();
    this.apiService.ticket.resendMessage(params)
      // .pipe(finalize(() => this.spinnerService.stop()))
      .subscribe(res => {
        const messages = {
          message: `Resend Message Status: ${ res.status }`,
          title: 'Success'
        }

        if (Utils.isEmpty(res)) {
          messages.message = 'There was an error with resending SMS message.';
          messages.title = 'Error';
        }

        this.spinnerService.stop();

        Utils.showMessageDialog(this.modalService, messages.message, 'OK', messages.title).then(() => {
          if (messages.title === 'Success') {
            const { company, ticketNo } = this.ticket;
            this.apiService.ticket.getTicket(ticketNo, company)
                .pipe(finalize(() => this.spinnerService.stop()))
                .subscribe(res => {
                  // this.ticketMessages = _.orderBy(res.messages, ['date'], ['desc']) || [];
                  this.ticketMessages = this.setTimeZone<Exception>(res.messages, [
                    'sentDate',
                    'schedule',
                  ]);
                }, err => Utils.showMessageDialog(this.modalService, err));
          }
        });
      }, err => {
        this.spinnerService.stop();
        Utils.showError(err);
      });
  }

  formatMessage(message) {
    return Utils.text2Html(message);
  }

  setTimeZone<T extends object>(portalList: T[], dateKeyList: string[]): T[] {
    return (portalList || []).reverse().map(p => {
      const dates = {};

      dateKeyList.forEach(d => {
        if (p[d]) {
          dates[d] = DateUtils.utc2HQDateTime(p[d]);
        }
      });

      return { ...(p as object), ...dates } as T;
    });
  }

}
