import { Pipe, PipeTransform } from '@angular/core';
import Utils from '@shared/utils/utils';

@Pipe({ name: 'capacity' })
export class CapacityPipe implements PipeTransform {

  transform(schedules) {
    let isSchedule = false;

    (schedules || []).some(schedule => {
      if (!Utils.isEmpty(schedule.capacity)) isSchedule = true
      return isSchedule;
    });

    return isSchedule;
  }

}
