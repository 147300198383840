import { Component, Input } from '@angular/core';
import ColorUtils from '@shared/utils/color-utils';
import Configs from '@shared/constants/configs';

@Component({
  selector: 'badge',
  template: '<span class="inline wd-xxs badge" ' +
    '[ngClass]="size"' +
    '[ngStyle]="badgeStyle">' +
    '<i *ngIf="icon" class="{{icon}}"></i>'+
    '{{text}}</span>',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent {

  private _text: string;
  private _icon: string = null;
  private _size: string = 'wd-xxs';
  private _color: string;
  private _textColor: string = '#fff';

  @Input()
  set text(value: string) {
    this._text = value;
    /*if (value) {
      value = value.toString().toUpperCase();
      switch (value) {
        case 'EMAIL ONLY':
          this._color = 'red';
          break;
        case 'OST':
          this._color = 'orange';
          break;
        case 'RECALL':
          this._color = 'purple';
          break;
        case 'REDO':
          this._color = 'red';
          break;
      }
    }*/
  }

  get text(): string {
    return this._text;
  }

  @Input()
  set icon(value: string) {
    this._icon = value;
  }

  get icon(): string {
    return this._icon;
  }

  @Input()
  set color(value: string) {
    this._color = value || Configs.badgeColor.default;
    /*let textColor = ColorUtils.stringToColor(value).fgColor;
    switch (value) {
      case Configs.badgeColor.couponStatus.expired:
        textColor = '#fff';
        break;
      case Configs.badgeColor.reason:
        // textColor = '#fff';
        break;
      default:
        break;
    }
    this._textColor = textColor;*/
    this._textColor = '#ffffff';
  }

  get color(): string {
    return this._color;
  }

  @Input()
  set size(value: string) {
    this._size = value;
  }

  get size(): string {
    return this._size;
  }

  get badgeStyle(): object {
    if (this.color) {
      return {
        color: this._textColor,
        background: this.color
      }
    } else {
      return {
        background: '#eee'
      }
    }
  }

}
