import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LogIn } from '@shared/models/login';
import { ApiService } from '@shared/apis';
import { Router } from '@angular/router';
import sharedStorage from '@shared/services/shared-storage';
import * as _ from 'lodash-es';
import Role from '@shared/constants/role';
import { Company } from '@shared/enums/company';
import * as moment from 'moment';
import SharedStorage from '@shared/services/shared-storage';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Injectable()
export class AuthService {
  readonly tokenKeyName = 'token';
  readonly companyKeyName = 'company';
  readonly mobileKeyName = 'mobile_token';
  readonly mobileURLKeyName = 'mobile_url';

  private currentCompany: string = Company.Sea;

  readonly tokenRenewCutoff = 60 * 1000; // 60 sec left token is value

  private token: any;
  public token$ = new BehaviorSubject({});
  public redirectUrl: any;

  constructor(
    private apiService: ApiService,
    private router: Router,
  ) {
    this.loadToken();
  }

  login(data: LogIn): Observable<boolean> {
    return this.apiService.auth.login(data)
      .pipe(
        map(result => {
          result.loginAt = moment().add(60, 'seconds');
          const { rememberMe } = data;
          this.setToken(result, rememberMe);
          return result;
        })
      );
  }

  otpLogin(username, code, tfaToken, trust): Observable<boolean> {
    return this.apiService.auth.otpLogin(username, code, tfaToken, trust)
      .pipe(
        map(result => {
          this.setToken(result);
          this.deviceId = result['deviceId'];
          return result;
        })
      );
  }

  resendOtp(username, tfaToken): Observable<boolean> {
    return this.apiService.auth.resendOtp(username, tfaToken)
      .pipe(
        map(result => {
          this.setToken(result);
          return result;
        })
      );
  }

  logout(): void {
    this.setToken(null);
  }

  setToken(token, rememberMe = false): void {
    if (token?.token?.access_token) {
      const tokenStr = JSON.stringify(token);
      if (rememberMe) {
        localStorage.setItem(this.tokenKeyName, tokenStr);
      } else {
        localStorage.removeItem(this.tokenKeyName);
      }
      sharedStorage.setItem(this.tokenKeyName, tokenStr);
    } else {
      localStorage.removeItem(this.tokenKeyName);
      sharedStorage.removeItem(this.tokenKeyName);
    }

    this.processToken(token);
  }

  getToken() {
    if (this.token) {
      return this.token;
    }

    return this.loadToken();
  }

  loadToken() {
    let tokenStr = sharedStorage.getItem(this.tokenKeyName);
    let token = null;

    if (!tokenStr) {
      tokenStr = localStorage.getItem(this.tokenKeyName);
    }

    if (tokenStr) {
      sharedStorage.setItem(this.tokenKeyName, tokenStr);
      token = JSON.parse(tokenStr);
      this.processToken(token);
    }
    return token;
  }

  private processToken(token): void {
    this.token = token;

    this.token$.next(this.token);

    if (token === null) {
      this.router.navigate(['/auth/login']);
    }
  }

  refreshToken(): Observable<any> {
    const { token } = this.getToken();

    if (!token || !token.refresh_token) {
      return EMPTY;
    }

    return this.apiService.auth.refreshToken(token.refresh_token);
  }

  getValidAccessTokenPromise() {
    return new Promise((resolve, reject) => {
      const token = this.getToken();

      if (moment(token.loginAt).diff(moment(), 'seconds') > 0) {
        // token has 60 sec to be expired
        return resolve(token.access_token);
      }

      this.apiService.auth.refreshToken(token.refresh_token).subscribe(res => {
        this.setToken(res);
        resolve(res.access_token);
      }, err => {
        reject(err);
      });
    })
  }

  isAuthenticated(): boolean {
    return this.getToken() != null;
  }

  getAccessToken(): string {
    const { token } = this.getToken() || '';
    return token ? token.access_token : ''
  }

  hasRole(roles, company = this.getCurrentCompany()) {
    if (!this.token) {
      return false;
    }

    if (!Array.isArray(roles)) {
      roles = [roles];
    }

    if (_.isEmpty(roles)) {
      return false;
    }

    const getRoles = this.token.roles || [];
    return getRoles.some(role => {
      if (company && role.company !== company) {
        return false;
      }
      const groups = role.groups || [];
      if (groups.includes(Role.admin)) {
        return true;
      }

      return groups.some(authority => {
        return roles.includes(authority);
      });
    });
  }

  hasType(roles) {
    if (!this.token) {
      return false;
    }

    if (!Array.isArray(roles)) {
      roles = [roles];
    }

    if (_.isEmpty(roles)) {
      return false;
    }

    const { userType } = this.getToken();

    return roles.includes(userType);
  }

  getRoles() {
    if (!this.token || !this.token.roles) {
      return [];
    }
    const findRole = this.token.roles.find(f => f.company === this.getCurrentCompany());
    return _.get(findRole, 'groups', []);
  }

  getCurrentCompany(): string {
    if (this.currentCompany) {
      return this.currentCompany || Company.Sea;
    }

    return localStorage.getItem(this.companyKeyName);
  }

  getUser() {
    const token = this.getToken();
    const user: any = {
      firstName: token?.firstName,
      lastName: token?.lastName,
      username: token?.username,
      key: token?.token?.user.id,
    }

    return user || {};
  }

  get deviceId(): string {
    return localStorage.getItem('deviceId');
  }

  set deviceId(value: string) {
    if (!value) {
      localStorage.removeItem('deviceId');
    } else {
      localStorage.setItem('deviceId', value);
    }
  }

  get mobileRedirectUrl() {
    return localStorage.getItem(this.mobileURLKeyName);
  }

  set mobileRedirectUrl(value) {
    if (!value) {
      localStorage.removeItem(this.mobileURLKeyName);
    } else {
      localStorage.setItem(this.mobileURLKeyName, value);
    }
  }


  setMobileToken(token, rememberMe = false): void {
    if (token) {
      const tokenStr = JSON.stringify(token);
      if (rememberMe) {
        localStorage.setItem(this.mobileKeyName, tokenStr);
      } else {
        localStorage.removeItem(this.mobileKeyName);
      }
      sessionStorage.setItem(this.mobileKeyName, tokenStr);
      this.token = {
        token: {
          access_token: token,
          token_type: 'Bearer'
        }
      };
    } else {
      localStorage.removeItem(this.mobileKeyName);
      sessionStorage.removeItem(this.mobileKeyName);
      this.token = token;
    }
  }

  isMobileAuthenticated(): boolean {
    if (this.token) {
      return this.token;
    }
    const tokenStr = sessionStorage.getItem(this.mobileKeyName) || localStorage.getItem(this.mobileKeyName) || null;
    if (tokenStr) {
      this.token = JSON.parse(tokenStr);
    }
    return this.token;
  }

  getMobileToken() {
    const token = localStorage.getItem(this.mobileKeyName) || sessionStorage.getItem(this.mobileKeyName);
    this.token = {
      token: {
        access_token: JSON.parse(token),
        token_type: 'Bearer'
      }
    };
    return this.token;
  }

  // region # Authenticator

  getQRCode(): Observable<any> {
    return this.apiService.auth.getQRCode()
      .pipe(
        map(result => {
          return result;
        })
      );
  }

  registerMfa(code): Observable<boolean> {
    return this.apiService.auth.registerMfa(code)
      .pipe(
        map(result => {
          return result.result;
        })
      );
  }  // endregion

  mfaLogin(username, code, tfaToken, trust?): Observable<boolean> {
    return this.apiService.auth.mfaLogin(username, code, tfaToken, trust)
      .pipe(
        map(result => {
          this.setToken(result);
          this.deviceId = result['deviceId'];
          return result;
        })
      );
  }  // endregion
}
